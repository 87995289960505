import React from 'react';
import Colors from '../../assets/styles/Colors';

interface PopUpConsentProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const PopUpConsent: React.FC<PopUpConsentProps> = ({ onCancel, onConfirm }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(34, 34, 34, 0.75)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >

      <div
        style={{
          width: '343px',
          backgroundColor: Colors.WHITE,
          borderRadius: '8px',
          padding: '32px 24px 24px 24px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0,0,0,0.15)',
          zIndex: 1001,
        }}
      >

        <div style={{ textAlign: 'center', fontSize: '16px', lineHeight: '26px', fontFamily: 'Krungsri_Light'}}>
          {"การถอนความยินยอมนี้ จะทำให้คุณไม่ได้รับ"}<br/>
          {"ข้อเสนอผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษ"} <br/> 
          {"ที่คัดสรรมาสำหรับคุณโดยเฉพาะ เพื่อให้ตอบ"} <br/>
          {"โจทย์ทุกความต้องการทางการเงิน"} <br/> 
          {"ให้กับคุณมากยิ่งขึ้น"}
        </div>

        <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '40px',
                gap: '16px', 
        }}>
            <button
                onClick={onCancel}
                style={{
                alignItems: 'center',
                background: Colors.WHITE,
                border: `1px solid ${Colors.STROKE_NORMAL}`,
                borderRadius: '3px',
                color: Colors.SECONDARY_BROWN,
                display: 'flex',
                flexShrink: 0,
                fontFamily: 'Krungsri_Bold',
                fontSize: '16px',
                fontStyle: 'normal',
                height: '52px',
                flex: 1, 
                justifyContent: 'center',
                lineHeight: '28px',
                textAlign: 'center',
                maxWidth: 'calc(50% - 8px)', 
                }}
            >
                ยกเลิก
            </button>

            <button
                onClick={onConfirm}
                style={{
                alignItems: 'center',
                background: Colors.PRIMARY_KS_YELLOW,
                border: `1px solid ${Colors.WHITE}`, 
                borderRadius: '3px',
                color: Colors.SECONDARY_BROWN,
                display: 'flex',
                flexShrink: 0,
                fontFamily: 'Krungsri_Bold',
                fontSize: '16px',
                fontStyle: 'normal',
                height: '52px',
                flex: 1, 
                justifyContent: 'center',
                lineHeight: '28px',
                textAlign: 'center',
                maxWidth: 'calc(50% - 8px)',
                }}
            >
                ถอนความยินยอม
            </button>
        </div>

      </div>
    </div>
  );
};
