import { call, put, take, takeLatest } from 'redux-saga/effects';
import { ADD_PRODUCT, GET_SURVEYS_LIST, setSurveysList, SAVE_SURVEYS_LIST, GET_USER_SURVEY, DELETE_ACCOUNT } from "../actions/accountAction";
import { storeOldUserSurvey } from '../actions/settingAction';
import { setBankAccounts } from "../actions/registerAction";
import { apiAddAccount, apiGetSurveysList, apiSaveSurveysList, apiGetUserSurvey, apiDeleteAccount, apiSoftAddAccount } from "../api/api";
import { showSpinner, hideSpinner, showPopupValid, hidePopupValid, showPopupAlert, hidePopupAlert } from "../components/common/AppProvider";
import { handleStatusError } from "./rootSaga";
import { safeNavigate } from '../utils/navigation';
import { pushEvent11, pushEvent12, pushEvent13, pushEvent3, pushEvent4, pushEvent6 } from '../utils/dataLayers/register_after_otp_layer';
import { logEvent } from '../utils/dataLayers/analytics';
import { errorMessages } from '../utils/errorCode';

function* workerAddProduct(payload: any): Generator<any, void, any> {
    const { accountNumber, oldBankList, isFirstAccount, defaultBankAccounts } = payload;
    const flow = sessionStorage.getItem('flow');
    try {
        yield call(showSpinner);

        if (defaultBankAccounts && defaultBankAccounts.length > 0) {
            const existingAccount = defaultBankAccounts.find(
                (account: { accountNumber: any; }) => account.accountNumber === accountNumber
            );

            if (existingAccount) {
                const updatedBankList = [
                    ...oldBankList,
                    {
                        accountId: existingAccount.accountId,
                        accountNumber: existingAccount.accountNumber,
                        accountType: existingAccount.accountType,
                        isNotificationEnabled: existingAccount.isNotificationEnabled,
                        accountStatus: existingAccount.accountStatus,
                    },
                ];

                yield put(setBankAccounts(updatedBankList));

                showPopupValid({
                    title: 'เพิ่มบัญชีสำเร็จ'
                });
                if (flow === 'register') {
                    pushEvent11();
                    pushEvent6();
                } else {
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_manage_account_pop_up',
                        action: 'add_more_product_popup',
                        label: 'add_account_completed'
                    });
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_manage_account',
                        action: 'turn_on',
                        label: 'notification',
                    });
                }

                setTimeout(() => {
                    hidePopupValid();
                    safeNavigate('/confirm-account');
                }, 2000);
                
                return;  // Return early since we found the existing account and handled it
            }
        }

        // If no existing account, continue with API call
        let response;
        if (flow === 'confirm-account') {
            response = yield call(apiSoftAddAccount, accountNumber);
        } else {
            response = yield call(apiAddAccount, accountNumber);
        }

        if (response.success) {
            const updatedBankList = [
                ...oldBankList,
                {
                    accountNumber: response.data.accountNumber,
                    accountType: response.data.accountType,
                    isNotificationEnabled: true,
                    accountStatus: "Active"
                },
            ];

            yield put(setBankAccounts(updatedBankList));

            showPopupValid({
                title: 'เพิ่มบัญชีสำเร็จ'
            });

            if (flow === 'register') {
                pushEvent11();
                pushEvent6();
            } else {
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_manage_account_pop_up',
                    action: 'add_more_product_popup',
                    label: 'add_account_completed'
                });
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_manage_account',
                    action: 'turn_on',
                    label: 'notification',
                });
            }

            setTimeout(() => {
                hidePopupValid();
                safeNavigate('/confirm-account');
            }, 2000);
        } else {
            if (response.code === 'LKS0504') {
                const { title, description } = errorMessages["LKS0504"];
                showPopupAlert({
                    title: title,
                    description: description,
                    iconType: 1,
                    ButtonText: 'ตกลง',
                    ButtonClick: () => {
                        hidePopupAlert();
                        switch (flow) {
                            case 'confirm-account':
                                logEvent({
                                    event: 'track_event',
                                    category: 'line_krungsri_simple_manage_account_error_pop_up',
                                    action: 'click_button',
                                    label: 'agree',
                                    error: 'account_not_support'
                                })
                                break;
                            default:
                                if (isFirstAccount) {
                                    pushEvent4('account_not_support');
                                } else {
                                    pushEvent13('account_not_support');
                                }
                                break;
                        }
                    },
                })
                if (flow === 'confirm-account') {
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_manage_account_error_pop_up',
                        action: 'add_more_account_error',
                        label: 'account_not_support'
                    })
                } else {
                    if (isFirstAccount) {
                        pushEvent3('account_not_support');
                    } else {
                        pushEvent12('account_not_support');
                    }
                }
            }
            else {
                yield call(handleStatusError, response.code, response.message);
            }
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherAddProduct(): Generator<any, void, any> {
    while (true) {
        const action = yield take(ADD_PRODUCT);
        yield call(workerAddProduct, action.payload);
    }
}

function* workerDeleteAccount(payload: any): Generator<any, void, any> {
    const { accountId, updatedBankAccounts } = payload;
    try {
        yield call(showSpinner);
        const response = yield call(apiDeleteAccount, accountId);
        if (response.success) {

        } else {
            yield call(handleStatusError, response.statusCode, response.message);
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherDeleteAccount(): Generator<any, void, any> {
    while (true) {
        const action = yield take(DELETE_ACCOUNT);
        yield call(workerDeleteAccount, action.payload);
    }
}

function* workerGetSurveysList(): Generator<any, void, any> {
    try {
        yield call(showSpinner);
        const response = yield call(apiGetSurveysList, 'verifyToken');
        if (response.success) {
            const mappedChoices = response.data.choices.map((choice: string, index: number) => ({
                id: (index + 1).toString(),
                name: choice,
                type: 2,
            }));
            const surveyList = {
                uid: response.data.uid,
                data: mappedChoices
            }
            yield put(setSurveysList(surveyList))
        } else {
            yield call(handleStatusError, response.code, response.message);
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherGetSurveysList(): Generator<any, void, any> {
    yield takeLatest(GET_SURVEYS_LIST, workerGetSurveysList);
}

function* workerSaveurveysList(payload: any): Generator<any, void, any> {
    try {
        yield call(showSpinner);
        const response = yield call(apiSaveSurveysList, payload);
        if (response.success) {
            safeNavigate('/success')
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherSaveurveysList(): Generator<any, void, any> {
    while (true) {
        const action = yield take(SAVE_SURVEYS_LIST);
        yield call(workerSaveurveysList, action.payload);
    }
}

function* workerGetUserSurvey(): Generator<any, void, any> {
    try {
        yield call(showSpinner);

        const response = yield call(apiGetUserSurvey);
        if (!response.success) {
            yield call(handleStatusError, response.statusCode, response.message);
            return;
        }

        const surveyListResponse = yield call(apiGetSurveysList, 'accessToken' );
        if (!surveyListResponse.success) {
            yield call(handleStatusError, surveyListResponse.statusCode, surveyListResponse.message);
            return;
        }

        const userSurveyMap = new Map(Object.entries(response.data.answers));
        const oldChoices = Array.from(userSurveyMap.entries())
            .filter(([userChoice]) => !surveyListResponse.data.choices.includes(userChoice))
            .map(([userChoice, _], idx) => ({
                id: (idx + 1).toString(),
                name: userChoice,
            }));

        if (oldChoices.length > 0) {
            yield put(storeOldUserSurvey(oldChoices));
        }
        const mappedChoices = surveyListResponse.data.choices.map((choice: string, index: number) => {
            const userChoice = userSurveyMap.get(choice);
            return {
                id: (index + 1).toString(),
                name: choice,
                type: userChoice ? 0 : 2
            };
        });
        const surveys = {
            uid: response.data.uid,
            data: mappedChoices
        }
        yield put(setSurveysList(surveys));
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherGetUserSurvey(): Generator<any, void, any> {
    yield takeLatest(GET_USER_SURVEY, workerGetUserSurvey);
}
