import { call, put, take } from 'redux-saga/effects';
import { safeNavigate } from '../utils/navigation';
import { EXCHANGE_LINE_TOKEN } from '../actions/tokenAction';
import { apiGetVerify } from '../api/api';
import { showSpinner, hideSpinner } from '../components/common/AppProvider';
import { handleStatusError } from './rootSaga';
import { fetchPreSettingCondition } from '../actions/settingAction';
import { SagaIterator } from 'redux-saga';
import { workerVerifyDeepLink } from './registerSaga';

function* workerExchangeLineToken(payload: any): SagaIterator {
  try {
    const { lineToken, nextPage } = payload;
    yield call(showSpinner);
    console.log('nextPage',nextPage)
    if (lineToken) {
      const response = yield call(apiGetVerify, lineToken);
      if (response.success) {
        sessionStorage.setItem('accessToken', response.data.accessToken);
        if (nextPage) {
          if (nextPage === '/confirm-account') {
            sessionStorage.setItem('flow', 'register');
            yield call(workerVerifyDeepLink)
          } else if (nextPage === '/confirm-account-setting') {
            sessionStorage.setItem('flow', 'confirm-account');
            yield call(workerVerifyDeepLink)
          } else if (nextPage === '/manage-consent') {
            sessionStorage.setItem('flow', 'manage-consent');
            yield call(workerVerifyDeepLink)
          } else if (nextPage === '/unbinding') {
            sessionStorage.setItem('flow', 'unbinding');
            yield call(workerVerifyDeepLink)
          } else if (nextPage === '/select-statement') {
            sessionStorage.setItem('flow', 'select-statement');
            yield call(workerVerifyDeepLink)
          } else if (nextPage === '/setting') {
            yield put(fetchPreSettingCondition());
          } else {
            safeNavigate(nextPage);
          }
        }
      } else {
        yield call(handleStatusError, response.code, response.message);
      }
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherExchangeLineToken(): SagaIterator {
  while (true) {
    const action = yield take(EXCHANGE_LINE_TOKEN);
    yield call(workerExchangeLineToken, action.payload);
  }
}