import React, { useEffect, useRef } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';
import BottomSpan from '../../../components/layout/BottomSpan';
import Button from '../../../components/common/Button';
import liff from '@line/liff';

import { pushManageSurveyComplete } from '../../../utils/dataLayers/manage_survay_layer';

const SuccessSettingPage: React.FC = () => {
    const pushEvent = useRef(false);
    useEffect(() => {
        if (!pushEvent.current) {
            pushManageSurveyComplete();
            pushEvent.current = true;
        }
    }, []);

    return (
        <PageProvider>
            <div style={{
                margin: '0 auto',
                textAlign: 'center',
                paddingTop: '23vh',
            }}>
                <img src={'/svg/valid.svg'} alt="Popup Icon" style={{
                    width: '88px',
                    height: '88px',
                    marginBottom: '4vh',
                }} />
                <div style={{ color: Colors.MAIN_TEXT_COLOR, fontFamily: Fonts.Krungsri_Bold, fontSize: '24px', lineHeight: '36px' }}>
                    {'ขอบคุณสำหรับข้อมูล'}
                </div>
            </div>
            <BottomSpan>
                <Button text={'กลับสู่หน้า LINE'} onClick={() => liff.closeWindow()} type={0} />
            </BottomSpan>
        </PageProvider>
    );
};

export default SuccessSettingPage;
