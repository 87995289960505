import ReactGA from "react-ga4";

type CustomDimensions = { [key: string]: any };

interface LogEventParams {
  event: string;
  category: string;
  action: string;
  label: string;
  error?: string;
  customDimensions?: CustomDimensions;
  analyticsProviders?: string[];
}

export const logEvent = ({
  event,
  category,
  action,
  label,
  error,
  customDimensions = {},
  analyticsProviders = ['GTM'],
}: LogEventParams): void => {
  analyticsProviders.forEach(provider => {
    switch (provider) {
      case 'GA':
        if (typeof ReactGA !== 'undefined') {
          ReactGA.event({
            category,
            action,
            label,
            ...customDimensions,
          });
        } else {
          console.warn('ReactGA is not defined');
        }
        break;
      case 'GTM':
        window.dataLayer = window.dataLayer || [];
        const dataLayerEvent: { [key: string]: any } = {
          event,
          event_category: category,
          event_action: action,
          event_label: label,
          ...customDimensions,
        };
        if (error) {
          dataLayerEvent.error_reason = error;
          error = ''; //clear error
        }
        window.dataLayer.push(dataLayerEvent);
        break;
      default:
        console.warn(`Unknown analytics provider: ${provider}`);
        break;
    }
  });
};