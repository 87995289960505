import CustomRadio from './CustomRadio';
import Colors from '../../assets/styles/Colors';
import { Description } from './Texts';

interface StatementInfoProps {
  text: string;
  size: number;
  checked: boolean;
  onChange: () => void;
}

export default function StatementInfo({ text, size, checked, onChange }: StatementInfoProps) {
  return (
    <div style={
      {
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'space-between',
        width: '100%',
        padding: '8px 0',
      }
    }>
      <CustomRadio
        size={size}
        checked={checked}
        onChange={onChange}
      />
      <Description>
      {text}
      </Description>
    </div>
  );
};
