import React, { CSSProperties, useEffect, useState } from 'react';
import { Description } from './Texts';
import { useNavigate } from 'react-router-dom';
import CheckButton from './CustomRadio';
import { PopUpConsent } from './PopUpConsent';
import ConsentDetail from '../../features/register/pages/ConsentDetailPage';
import { Link } from 'react-router-dom';
import Colors from '../../assets/styles/Colors';
import { Fonts } from '../../assets/styles/Fonts';
import { logEvent } from '../../utils/dataLayers/analytics';

interface Information {
  action: string;
  entity: string;
  channel: string;
  updatedAt: string;
  signedVersion: string;
  consentReference: string;
}

type ConsentProps = {
  id: string
  question: string;
  remark?: string;
  information?: Information;
  index: number
  action?: string;
  type?: number
  onChange: (index: number, action: string) => void;
};

const ConsentForm: React.FC<ConsentProps> = ({ id, question, remark, information, action, index, type, onChange }) => {
  const [isRemarkOpen, setIsRemarkOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selected, setSelected] = useState(action);
  const [defaultAction, setDefaultAction] = useState('');

  useEffect(() => {
    if (information) setDefaultAction(information?.action);
  }, [])

  const handleApproved = () => {
    if (selected !== 'ยินยอม') {
      onChange(index, 'Y')
      setSelected("ยินยอม")
    }
  };

  const handleRejected = () => {
    if (!type) {
      if (selected !== 'ไม่ยินยอม') {
        onChange(index, 'P')
        setSelected("ไม่ยินยอม")
      }
    } else {
      if (selected != 'ไม่ยินยอม') {
        setShowPopup(true);
      }
    }
  };

  function handleCancel(): void {
    setShowPopup(false);
  }

  function handleConfirm(): void {
    onChange(index, 'W')
    setSelected("ไม่ยินยอม")
    setShowPopup(false);
  }

  const goToDetailPage = (id: string) => {
    setShowDetail(true);
    logEvent({
      event: 'track_event',
      category: 'line_krungsri_simple_manage_consent',
      action: 'click_button',
      label: 'consented_detail',
    })
  }

  const form: CSSProperties = {
    marginTop: index == 0 ? '24px' : "32px",
    display: 'flex',
    flexDirection: 'column',
  }

  const subTitle: CSSProperties = {
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Krungsri_Medium',
  };

  const description: CSSProperties = {
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Krungsri_Light',
  };

  return (
    <div style={form}>
      <div style={{ fontFamily: Fonts.Krungsri_Light, fontSize: '14px', lineHeight: '22px', color: Colors.TEXT_BODY_PRIMARY }}
        dangerouslySetInnerHTML={{
          __html: `<style>p, h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0; } </style>${question}`
        }} />
      <div style={{ padding: '8px 12px', marginTop: '12px', marginBottom: '12px', backgroundColor: "#FFFBE6", borderRadius: '8px' }}>
        <div style={{ display: 'inline', fontFamily: Fonts.Krungsri_Light, fontSize: '14px', lineHeight: '22px', color: Colors.TEXT_BODY_SECONDARY }}>
          คุณรับทราบว่ากำลังพิจารณาให้ความยินยอม
        </div>
        <span>
          <div style={{ display: 'inline', fontFamily: Fonts.Krungsri_Bold, fontSize: '14px', lineHeight: '24px', color: Colors.TEXT_BODY_SECONDARY }}>
            แก่ธนาคารและบริษัทในกลุ่มกรุงศรี
          </div>
        </span>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'center', }}>
        <label style={{ fontSize: '16px', fontFamily: Fonts.Krungsri_Bold, lineHeight: '28px', cursor: 'pointer', width: '100%', color: Colors.TEXT_BODY_SECONDARY }}>
          <div onClick={handleApproved} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <img
              src={selected === "ยินยอม" ? '/svg/radioSelectButton.svg' : '/svg/radioButton.svg'}
              alt="radio button"
              style={{ width: '24px', height: '24px' }}
            />
            <div>
              ยินยอม
            </div>
          </div>
        </label>

        <label style={{ fontSize: '16px', fontFamily: Fonts.Krungsri_Bold, lineHeight: '28px', cursor: 'pointer', width: '100%', color: Colors.TEXT_BODY_SECONDARY }}>
          <div onClick={handleRejected} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <img
              src={selected === "ไม่ยินยอม" ? '/svg/radioSelectButton.svg' : '/svg/radioButton.svg'}
              alt="radio button"
              style={{ width: '24px', height: '24px' }}
            />
            <div>
              ไม่ยินยอม
            </div>
          </div>
        </label>
      </div>

      {remark && !type && (
        <div style={{ marginTop: '12px', padding: '16px', backgroundColor: '#EBEBEB', borderRadius: '8px' }}>
          <Description style={{ fontSize: '14px', fontFamily: Fonts.Krungsri_Light, lineHeight: '22px', color: Colors.TEXT_BODY_PRIMARY }}>
            {remark}
          </Description>
        </div>
      )}

      {
        type && (
          <div style={{ margin: '32px 0 0 0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            onClick={() => goToDetailPage(index.toString())}>
            <div style={{ ...subTitle, fontWeight: 700 }}>
              รายละเอียดความยินยอม
            </div>

            <div>
              <img src="/svg/chevronRight.svg"
                width={20} height={20} />
            </div>
          </div>
        )
      }

      {
        type && remark && (id == '62' || id == '63') && (
          <div>
            <div style={{ margin: '32px 0 0 0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              onClick={() => setIsRemarkOpen(!isRemarkOpen)}>
              <div style={{ ...subTitle, fontWeight: 700 }}>
                {id == '62' ? "บริษัทในกลุ่มกรุงศรี" : "พันธมิตรของกลุ่มกรุงศรี"}
              </div>

              <div>
                <img src="/svg/chevronRight.svg"
                  width={20} height={20}
                  style={{
                    transform: isRemarkOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
                    transition: 'transform 0.3s ease'
                  }} />
              </div>
            </div>

            {
              isRemarkOpen && (
                <div style={{ ...description, marginTop: '8px' }}>
                  {remark}
                </div>
              )
            }
          </div>
        )
      }

<div
            style={{
                background: Colors.STROKE_NORMAL,
                height: 1,
                marginTop: '32px',
                width: "100%",
            }}>
        </div>
      {
        showDetail && information && (
          <ConsentDetail
            action={defaultAction}
            entity={information.entity}
            channel={information.channel}
            updatedAt={information.updatedAt}
            signedVersion={information.signedVersion}
            consentReference={information.consentReference}
            onClose={() => setShowDetail(false)}
          />
        )
      }
      {showPopup && (
        <PopUpConsent
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default ConsentForm;
