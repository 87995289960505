export const STORE_OLD_USER_SURVEY = 'STORE_OLD_USER_SURVEY'
export const FETCH_PRE_SETTING_CONDITION = 'FETCH_PRE_SETTING_CONDITION';
export const CHECK_PRE_SETTING_CONDITION = 'CHECK_PRE_SETTING_CONDITION';
export const SAVE_SURVEYS_SETTING = 'SAVE_SURVEYS_SETTING'
export const UNREGISTER_USER = 'UNREGISTER_USER'
export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT'
export const FETCH_PRE_VERIFY_CUSTOMER = 'FETCH_PRE_VERIFY_CUSTOMER'
export const STAMP_START_LIFF = 'STAMP_START_LIFF'

export const fetchPreSettingCondition = () => {
  return { type: FETCH_PRE_SETTING_CONDITION };
};

export const fetchPreVerifyCustomer = (verifyBy: string) => {
  return { 
    type: FETCH_PRE_VERIFY_CUSTOMER,
    payload: { verifyBy }
  };
};

export const checkPreSettingCondition = (isFillConsentAllowed: boolean) => {
  return {
    type: CHECK_PRE_SETTING_CONDITION,
    payload: { isFillConsentAllowed }, 
  };
};

export const storeOldUserSurvey = (oldSurvey: {id: string; name: string}[]) => {
    return {
      type: STORE_OLD_USER_SURVEY,
      payload: { oldSurvey },
    }
  };

export const saveSurveysSetting = (answers: string[], id :string) => {
    return {
        type: SAVE_SURVEYS_SETTING,
        payload: { answers,id },
    }
};

export const unregisterUser = (deviceOS: string) => {
  return {
    type: UNREGISTER_USER,
    payload: { deviceOS }
  }
}

export const getBankAccount = () => {
  return {
    type: GET_BANK_ACCOUNT
  }
}
  
export const stampStartLiff = () => {
  return { type: STAMP_START_LIFF };
};