import React from "react";

const Header = () => (
    <header>
        <div style={{ display: 'flex', boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)', }}>
            <img
                alt="Krungsri bank Logo"
                src="/svg/bayLogo.svg"
                style={{
                    height: 48,
                    width: 104,
                    margin: '4px 0px 4px 8px',
                }}
            />
        </div>
    </header>
);

export default Header;
