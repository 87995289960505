import { logEvent } from "./analytics";

export const TRACK_EVENT = 'track_event';
export const CATEGORY_SURVEY = 'line_krungsri_simple_complete';
export const ACTION_CLICK_BUTTON = 'click_button';

export const pushFinishSurvey = (
  selectedProducts: (string | null)[],
  mapIndexOfProduct: (string)[]
) => {
  const customDimensions: Record<string, string | null> = {};

  selectedProducts.forEach((product, index) => {
    if (product !== null) {
      const indexOfProduct = mapIndexOfProduct[index];
      customDimensions[`interested_product_service_${indexOfProduct}`] = product;
    }
  });

  for (let i = 1; i <= 16; i++) {
    if (!customDimensions[`interested_product_service_${i}`]) {
      customDimensions[`interested_product_service_${i}`] = null;
    }
  }

  logEvent({
    event: 'track_event',
    category: 'line_krungsri_simple_complete',
    action: 'click_button',
    label: 'finish_survey',
    customDimensions,
  });
};


export const pushSurveyComplete = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_SURVEY,
    action: 'survey_popup',
    label: 'survey_completed',
  })
}
