import React, { useState, ChangeEvent, useEffect } from "react";
import PageProvider from '../../../components/layout/PageProvider';
import ScreenAlert from '../../../components/common/ScreenAlert';
import Colors from '../../../assets/styles/Colors';
import BottomSpan from "../../../components/layout/BottomSpan";
import Button from '../../../components/common/Button';
import { Title, Description } from '../../../components/common/Texts';
import StatementInfo from "../../../components/common/StatementInfo";
import CheckButton from "../../../components/common/CustomRadio";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateStatement, getAccountDetail } from "../../../actions/statementAction";
import { RootState } from "../../../reducers/rootReducer";
import { accountSelector } from "../../../reducers/accountReducer";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField, Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { addMonths, addDays } from 'date-fns';
import { Fonts } from "../../../assets/styles/Fonts";
import CalendarModal from "../components/CalendarModal";
import { useLineAuth } from "../../../hooks/useLineAuth";
import { exchangeLineToken } from "../../../actions/tokenAction";
import { setHashedMobileNumber } from "../../../actions/registerAction";
import { logEvent } from "../../../utils/dataLayers/analytics";

const baseInputStyle: React.CSSProperties = {
    boxSizing: 'border-box',
    outline: 'none',
    width: '100%',
    height: '44px',
    flexShrink: 0,
    borderRadius: '3px',
    border: `1px solid ${Colors.STROKE_NORMAL}`,
    background: Colors.WHITE,
    color: '#949494',
    fontFamily: Fonts.Thongterm_Medium,
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '29px',
    paddingLeft: '16px',
};

const inputTypeStyle: React.CSSProperties = {
    fontFamily: Fonts.Thongterm_Bold,
    color: '#222222',
};

const inputErrorStyle: React.CSSProperties = {
    borderColor: '#D00000',
    borderRadius: '3px',
};

const errorTextStyle: React.CSSProperties = {
    color: '#D00000',
};

export default function RequestStatementPage() {

    const dispatch = useDispatch()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const { lineIdToken } = useLineAuth();
    const hashedMobileNumber = localStorage.getItem('hashedMobileNumber');
    const [isKeyboard, setIsKeyboard] = useState<boolean>(false);

    useEffect(() => {
        const fetchAndExchangeToken = async () => {
            const decodedUri = decodeURIComponent(location.search);
            const params = new URLSearchParams(decodedUri);
            const authenticationReference = params.get('authenticationReference');

            if (authenticationReference) {
                sessionStorage.setItem('authRef', authenticationReference)
                if (lineIdToken) {
                    dispatch(exchangeLineToken(lineIdToken, '/select-statement'));
                }
            }
        };

        fetchAndExchangeToken();
    }, [lineIdToken, location.search]);

    const { accountData } = useSelector((state: RootState) => accountSelector(state));
    const accountNumber = accountData.accountNumber || 'XXX-X-XXXXX-X';
    const accountType = accountData.accountType || '';
    const [email, setEmail] = useState<string>('');
    const [isEmailFocus, setIsEmailFocus] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

    const [language, setLanguage] = useState<string>("TH");

    const [isFromCalendarOpen, setFromCalendarOpen] = useState(false);
    const [isToCalendarOpen, setToCalendarOpen] = useState(false);
    const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(null);
    const [selectedToDate, setSelectedToDate] = useState<Date | null>(null);

    const today = new Date();
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

    const handleFromClose = (date: Date, formattedDate: string) => {
        setSelectedFromDate(date);
        setFromCalendarOpen(false);
    };

    const handleToClose = (date: Date, formattedDate: string) => {
        setSelectedToDate(date);
        setToCalendarOpen(false);
    };

    const adjustHeight = () => {
        const height = window.innerHeight;
        if (height < 600) {
            setIsEmailFocus(true)
        } else {
            setIsEmailFocus(false)
        }
    };

    useEffect(() => {
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, []);

    const [selectedPeriod, setSelectedPeriod] = useState<number>(30);
    useEffect(() => {
        if (selectedPeriod !== -1) {
            const calculatedStartDate = new Date();
            setSelectedToDate(calculatedStartDate);

            const calculatedEndDate = addDays(addMonths(calculatedStartDate, -(selectedPeriod / 30)), 1);
            setSelectedFromDate(calculatedEndDate);

        } else {
            setSelectedToDate(null);
            setSelectedFromDate(null);
        }
    }, [selectedPeriod]);

    const thaiMonths = [
        'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
        'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
    ];

    const formatThaiDate = (date: Date): string => {
        const day = date.getDate();
        const month = thaiMonths[date.getMonth()];
        const year = (date.getFullYear() + 543) % 100;
        return `${day} ${month} ${year}`;
    };

    useEffect(() => {
        const prefillEmail = accountData?.email
        if (prefillEmail) {
            setEmail(prefillEmail);
            if (!validateEmail(prefillEmail)) {
                setEmailError("กรุณากรอกอีเมลให้ถูกต้อง เช่น simple@krungsri.com");
                setIsEmailValid(false);
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_request_statement',
                    action: 'field_error',
                    label: 'email',
                    error: 'not_correct',
                });
            } else {
                setEmailError("");
                setIsEmailValid(true);
            }
        }
    }, [accountData?.email]);


    function matchTypeAccount(type: string = ''): string {
        const typeToRoleMap: { [key: string]: string } = {
            'SVNG': 'บัญชีออมทรัพย์',
            'CURR': 'บัญชีกระแสรายวัน',
            'TIME': 'บัญชีเงินฝากประจำ',
        };

        return typeToRoleMap[type] || 'บัญชีออมทรัพย์';
    }

    const statementPeriod = [
        { label: '1 เดือนล่าสุด', value: 30 },
        { label: '3 เดือนล่าสุด', value: 90 },
        { label: '6 เดือนล่าสุด', value: 180 },
        { label: '9 เดือนล่าสุด', value: 270 },
        { label: '12 เดือนล่าสุด', value: 360 },
        { label: 'กำหนดเอง', value: -1 },
    ];

    const validateEmail = (email: string) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z\-0-9]{2,})+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const handleEmailFocus = () => {
        setIsEmailFocus(true);
    };

    const handleEmailBlur = () => {
        setIsEmailFocus(false);
        if (email.length === 0) {
            setEmailError("กรุณากรอกอีเมล");
            setIsEmailValid(false);
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_request_statement',
                action: 'field_error',
                label: 'email',
                error: 'not_filled',
            });
        } else if (!validateEmail(email)) {
            setEmailError("กรุณากรอกอีเมลให้ถูกต้อง เช่น simple@krungsri.com");
            setIsEmailValid(false);
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_request_statement',
                action: 'field_error',
                label: 'email',
                error: 'not_correct',
            });
        } else {
            setEmailError("");
            setIsEmailValid(true);
        }
    };

    const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const isValidInput = /^[A-Za-z0-9@._+-]*$/.test(value);
        if (isValidInput) {
            setEmail(value);
        }
    };

    const hashEmail = async (email: string): Promise<string> => {
        const encoder = new TextEncoder();
        const data = encoder.encode(email.toLowerCase());
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        return hashHex;
    };

    const formatToISOWithTimezone = (date: Date, time: string): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures 2 digits for month
        const day = String(date.getDate()).padStart(2, '0'); // Ensures 2 digits for day

        return `${year}-${month}-${day}${time}`;
    };

    const handleSubmit = async () => {
        console.log('selectedFromDate', selectedFromDate)
        console.log('selectedToDate', selectedToDate)

        const hashedEmail = await hashEmail(email);

        if (selectedFromDate && selectedToDate) {
            const fromBookingDateTime = formatToISOWithTimezone(selectedFromDate, 'T00:00:00+07:00')
            const toBookingDateTime = formatToISOWithTimezone(selectedToDate, 'T23:59:59+07:00')
            dispatch(generateStatement({ language, fromBookingDateTime, toBookingDateTime, email }))
        }

        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_request_statement',
            action: 'click_button',
            label: 'confirm_request_statement',
            customDimensions: {
                'user_email': hashedEmail,
                'user_phone': hashedMobileNumber
            },
        });
    };

    const getInputStyle = (hasError: boolean, isFocused: boolean, isType: boolean): React.CSSProperties => ({
        ...baseInputStyle,
        ...(hasError && !isFocused ? inputErrorStyle : {}),
        ...(isType ? inputTypeStyle : {}),
    });

    return (
        <PageProvider>
            <Title>{matchTypeAccount(accountType)} <br />{accountNumber}</Title>
            <div style={{ marginTop: 8 }}>
                <div style={{ marginBottom: 16 }}>
                    <Description>ขอ Statement ย้อนหลังได้สูงสุด 12 เดือน</Description>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div>
                        <Description>เลือกช่วงเวลา</Description>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                            <div>
                                {statementPeriod
                                    .filter((_, idx) => idx < 3)
                                    .map((val, idx) => (
                                        <StatementInfo
                                            key={idx}
                                            text={val.label}
                                            checked={selectedPeriod === val.value}
                                            size={24}
                                            onChange={() => setSelectedPeriod(val.value)}
                                        />
                                    ))}
                            </div>

                            <div>
                                {statementPeriod
                                    .filter((_, idx) => idx >= 3)
                                    .map((val, idx) => (
                                        <StatementInfo
                                            key={idx}
                                            text={val.label}
                                            checked={selectedPeriod === val.value}
                                            size={24}
                                            onChange={() => setSelectedPeriod(val.value)}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </LocalizationProvider>
                <div style={{ display: 'flex', gap: '16px', marginTop: '-8px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}> {/* Column layout for From */}
                        <Description>
                            ตั้งแต่
                        </Description>
                        <div onClick={() => selectedPeriod === -1 ? setFromCalendarOpen(true) : null} style={{ position: 'relative' }}>
                            <input
                                type="text"
                                value={selectedFromDate ? formatThaiDate(selectedFromDate) : 'กรุณาเลือก'}
                                readOnly
                                style={{
                                    borderRadius: '3px',
                                    padding: '8px', // Optional: Add padding for better appearance
                                    outline: 'none', // Optional: Remove outline on focus
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    fontFamily: selectedFromDate ? Fonts.Thongterm_Bold : Fonts.Thongterm_Medium,
                                    color: selectedFromDate ? Colors.MAIN_TEXT_COLOR : Colors.SECONDARY_TEXT_COLOR,
                                    fontSize: '16px',
                                    paddingLeft: '16px',
                                    height: '44px',
                                    flexShrink: 0,
                                    border: `1px solid ${Colors.STROKE_NORMAL}`,
                                    background: selectedPeriod !== -1 ? Colors.BACKGROUND_DISABLE : Colors.WHITE,
                                    fontStyle: 'normal',
                                    lineHeight: '29px',
                                }}
                            />
                            {
                                selectedPeriod === -1 &&
                                <img
                                    src={'/svg/calendar.svg'} // Path to your SVG icon
                                    alt="icon"
                                    style={{
                                        position: 'absolute',
                                        right: '16px',
                                        top: '50%',
                                        transform: 'translateY(-50%)', // Center the icon vertically
                                        width: '20px', // Adjust size as needed
                                        height: '20px', // Adjust size as needed
                                    }}
                                />
                            }

                        </div>
                        {isFromCalendarOpen && (
                            <CalendarModal
                                isOpen={isFromCalendarOpen}
                                onClose={handleFromClose}
                                initialDate={selectedFromDate || today}
                                maxDate={selectedToDate || today} // Prevent selecting a date after today
                            />
                        )}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}> {/* Column layout for To */}
                        <Description>
                            จนถึง
                        </Description>
                        <div onClick={() => selectedPeriod === -1 ? setToCalendarOpen(true) : null} style={{ position: 'relative' }}>
                            <input
                                type="text"
                                value={selectedToDate ? formatThaiDate(selectedToDate) : 'กรุณาเลือก'}
                                readOnly
                                style={{
                                    borderRadius: '3px',
                                    padding: '8px',
                                    outline: 'none', // Optional: Remove outline on focus
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    fontFamily: selectedToDate ? Fonts.Thongterm_Bold : Fonts.Thongterm_Medium,
                                    color: selectedToDate ? Colors.MAIN_TEXT_COLOR : Colors.SECONDARY_TEXT_COLOR,
                                    fontSize: '16px',
                                    paddingLeft: '16px',
                                    height: '44px',
                                    flexShrink: 0,
                                    border: `1px solid ${Colors.STROKE_NORMAL}`,
                                    background: selectedPeriod !== -1 ? Colors.BACKGROUND_DISABLE : Colors.WHITE,
                                    fontStyle: 'normal',
                                    lineHeight: '29px',
                                }}
                            />
                            {
                                selectedPeriod === -1 &&
                                <img
                                    src={'/svg/calendar.svg'} // Path to your SVG icon
                                    alt="icon"
                                    style={{
                                        position: 'absolute',
                                        right: '16px',
                                        top: '50%',
                                        transform: 'translateY(-50%)', // Center the icon vertically
                                        width: '20px', // Adjust size as needed
                                        height: '20px', // Adjust size as needed
                                    }}
                                />
                            }
                        </div>
                        {isToCalendarOpen && (
                            <CalendarModal
                                isOpen={isToCalendarOpen}
                                onClose={handleToClose}
                                initialDate={selectedToDate || today}
                                minDate={selectedFromDate || oneYearAgo} // Prevent selecting a date before the selected "From" date
                                maxDate={today} // Prevent selecting a date after today
                            />
                        )}
                    </div>
                </div>


                {/* <input
                    type="datetime-local"
                    id="datetime"
                /> */}
                <div>
                    <Description>เลือกภาษาของ Statement</Description>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px', marginTop: 8 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckButton
                                checked={language === 'TH'}
                                size={24}
                                onChange={() => setLanguage('TH')}
                            />
                            <Description>ไทย</Description>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckButton
                                checked={language === 'EN'}
                                size={24}
                                onChange={() => setLanguage('EN')}
                            />
                            <Description>English</Description>
                        </div>
                    </div>
                </div>
                <div>
                    <Description>ส่งไปที่อีเมล</Description>
                    <input
                        type="text"
                        placeholder="ตัวอย่าง: simple@krungsri.com"
                        value={email}
                        onChange={handleEmail}
                        onFocus={handleEmailFocus}
                        onBlur={handleEmailBlur}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                (e.target as HTMLInputElement).blur();
                            }
                        }}
                        style={getInputStyle(!!emailError, isEmailFocus, !!email)}
                    />
                    {!isEmailFocus && emailError && <div style={errorTextStyle}>{emailError}</div>}
                </div>
            </div>
            <BottomSpan>
                {!isEmailFocus &&
                    <Button
                        text={'ส่งคำขอ'}
                        type={!isEmailValid || !selectedPeriod || !selectedFromDate || !selectedToDate ? 1 : 0}
                        onClick={handleSubmit}
                    />}
            </BottomSpan>
        </PageProvider>
    );
}
