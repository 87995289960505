import React, { CSSProperties, useEffect, useRef } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import { Description, Title } from '../../../components/common/Texts';
import { useParams } from 'react-router-dom';
import Button from '../../../components/common/Button';
import { safeNavigate } from '../../../utils/navigation';
import { logEvent } from '../../../utils/dataLayers/analytics';


const SuccessConsent: React.FC = () => {
    const { withdraw } = useParams<{ withdraw: string }>();
    const pushEvent = useRef(false);
    const goToNextPage = () => {
        safeNavigate( "/setting");
    }

    useEffect(() => {
        if (!pushEvent.current) {
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_manage_consent',
                action: 'manage_consent_pop_up',
                label: 'manage_consent_completed'
            })
            pushEvent.current = true;
        }
    }, []);

    const titleText = withdraw === 'true' 
    ? (
        <div>
            {"ส่งคำขอถอนความยินยอม"}<br />
            {"เรียบร้อยแล้ว"}
        </div>
     )
    : (
        <div>
            {"ให้ความยินยอมและส่งคำขอ"}<br />
            {"ถอนความยินยอมเรียบร้อยแล้ว"}
        </div>
    );

    const title:CSSProperties = {
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: 'Krungsri_Bold',
        textAlign: 'center',
        margin: '8px 0px'
    };

    const description:CSSProperties = {
        fontSize: '14px',
        lineHeight: '22px',
        fontFamily: 'Krungsri_Light',
        textAlign: 'center'
    };

    return (
        <PageProvider>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',  
                    alignItems: 'center',      
                }}
                >
                <img
                    alt="IMAGE"
                    src="/svg/successConsent.svg"
                    style={{
                    height: 160,
                    width: 160,
                    }}
                />
            </div>

            <div style = {{margin: '24px 0 24px 0'}}>
                <Title style={title}>
                    {titleText}
                </Title>
                <Description style={description}>
                    {"กลุ่มกรุงศรีจะทำการเพิกถอนความยินยอมภายใน"} <br/>  
                    {"7 วันทำการ หากไม่มีผลกระทบต่อการใช้บริการ"} <br/>
                    {"ขั้นพื้นฐานของท่าน"} 
                </Description>
            </div>

            <ButtomSpan>
                <div style={{marginTop: '24px' , marginBottom: '16px'}}>
                    <Button text={'ยืนยัน'} onClick={goToNextPage} type={0} style={{width: '343px'}}/>
                </div>
            </ButtomSpan>
        </PageProvider>
    );
};

export default SuccessConsent;
