import { call, put, take, takeLatest } from 'redux-saga/effects';
import { apiCheckPreVerifyCustomer, apiCheckPreSettingCondition, apiSaveSurveysListSetting, apiUnregisterUser } from "../api/api";
import { FETCH_PRE_SETTING_CONDITION, checkPreSettingCondition, SAVE_SURVEYS_SETTING, UNREGISTER_USER, FETCH_PRE_VERIFY_CUSTOMER } from "../actions/settingAction";
import { showSpinner, hideSpinner, showPopupAlert, hidePopupAlert } from "../components/common/AppProvider";
import { handleStatusError } from "./rootSaga";
import { safeNavigate } from '../utils/navigation';
import { exchangeLineToken } from '../actions/tokenAction';
import { workerCreateDeepLink } from './registerSaga';
import { logEvent } from '../utils/dataLayers/analytics';
import { errorMessages } from '../utils/errorCode';
import { calculateTimeToUnlock } from '../utils/isExpired';
import { purpose } from '../utils/purpose';

function* workerCheckPreSettingCondition(): Generator<any, void, any> {
  try {
    // yield call(showSpinner);
    const response = yield call(apiCheckPreSettingCondition);
    if (response.success) {
      yield put(checkPreSettingCondition(response.data.isFillConsentAllowed));
    } else {
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}


export function* watcherCheckPreSettingCondition(): Generator<any, void, any> {
  yield takeLatest(FETCH_PRE_SETTING_CONDITION, workerCheckPreSettingCondition);
}

function* workerSaveSurveysSetting(payload: any): Generator<any, void, any> {
  try {
    yield call(showSpinner);
    const response = yield call(apiSaveSurveysListSetting, payload);
    if (response.success) {
      safeNavigate('/setting-success')
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherSaveSurveysSetting(): Generator<any, void, any> {
  while (true) {
    const action = yield take(SAVE_SURVEYS_SETTING);
    yield call(workerSaveSurveysSetting, action.payload);
  }
}

function* workerUnregisterUser(payload: any): Generator<any, void, any> {
  try {
    const { deviceOS } = payload
    yield call(showSpinner);
    const response = yield call(apiUnregisterUser);
    if (response.success) {
      localStorage.removeItem('scenarioId')
      safeNavigate('/success-unbinding')
      logEvent({
        event: 'track_event',
        category: 'line_krungsri_simple_unbinding',
        action: 'unbinding_pop_up',
        label: 'unbinding_completed',
    })
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherUnregisterUser(): Generator<any, void, any> {
  while (true) {
    const action = yield take(UNREGISTER_USER);
    yield call(workerUnregisterUser, action.payload);
  }
}

export function* workerCheckPreRequestOtp(payload: any): Generator<any, void, any> {
  try {
    yield call(showSpinner);
    const { verifyBy } = payload
    const flow = sessionStorage.getItem('flow') || 'register';
    const response = yield call(apiCheckPreVerifyCustomer, purpose(flow));
    if (response.success) {
      if (verifyBy === 'kma') {
        yield call(workerCreateDeepLink)
      } else {
        yield call(hideSpinner);
        safeNavigate('/receive-otp')
      }
    } else {
      yield call(hideSpinner);
      if (response.code === 'LKS0603') {
        const { title, description } = errorMessages["LKS0603"];
        safeNavigate('/locked-register', {
          header: 'ข้อมูลผู้สมัคร',
          iconSources: '/svg/alert.svg',
          title: title,
          desc: description,
          buttonText: 'กลับสู่หน้า LINE',
          time: calculateTimeToUnlock(response.metadata.cooldownFinishedAt),
          onCountdownEnd: '/receive-otp'
        });
      } else if (response.code === 'LKS0604') {
        const { title, description } = errorMessages["LKS0604"];
        safeNavigate('/suspend-register',{ header: title, description: description })
      } else {
        yield call(handleStatusError, response.code, response.message);
      }
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  }
}

export function* watcherCheckPreRequestOtp(): Generator<any, void, any> {
  while (true) {
    const action = yield take(FETCH_PRE_VERIFY_CUSTOMER);
    yield call(workerCheckPreRequestOtp, action.payload);
  }
}