import PageProvider from "../../../components/layout/PageProvider";
import { Title } from "../../../components/common/Texts";
import { Fonts } from '../../../assets/styles/Fonts';
import SettingOption from "../../../components/common/SettingOption";
import { fetchPreSettingCondition, stampStartLiff } from "../../../actions/settingAction";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { settingSelector } from "../../../reducers/settingReducer";
import { RootState } from "../../../reducers/rootReducer";
import { useLineAuth } from '../../../hooks/useLineAuth';
import { exchangeLineToken } from '../../../actions/tokenAction';
import { safeNavigate } from "../../../utils/navigation";
import { v4 as uuidv4 } from 'uuid';
import { logEvent } from "../../../utils/dataLayers/analytics";

const getGaClientId = () => {
    const match = document.cookie.match(/_ga=([^;]+)/);
    return match ? match[1] : null;
};

export default function SettingPage() {
    const { isFillConsentAllowed, isStartLiff } = useSelector((state: RootState) => settingSelector(state));
    const { uuid, lineIdToken } = useLineAuth()
    const dispatch = useDispatch();
    const effectRan = useRef(false);
    const lineGaClientId = getGaClientId();
    var sha256 = require("crypto-js/sha256");

    useEffect(() => {
        if (!effectRan.current && lineGaClientId && uuid) {
            const hashUUID = (uuid: string): string => {
                return sha256(uuid).toString();
            };
            
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_setting',
                action: 'land_to_setting_page',
                label: 'krungsri_simple_service',
                customDimensions: {
                    line_ga_client_id: lineGaClientId,
                    line_uuid: hashUUID(uuid),
                }
            })
            effectRan.current = true;
        }
    }, [lineGaClientId, uuid]);

    const goToNextPage = (page: string, buttonName: string) => {
        sessionStorage.setItem('flow', page);
        safeNavigate('/request-statement');
        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_setting',
            action: 'click_button',
            label: buttonName
        });
    };

    const goDirectNextPage = (page: string, buttonName: string) => {
        safeNavigate(`/${page}`, { page });
        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_setting',
            action: 'click_button',
            label: buttonName
        });
    };

    useEffect(() => {

        if (isStartLiff) {
            const scenarioId = uuidv4();
            localStorage.setItem('scenarioId', scenarioId)
            dispatch(stampStartLiff())
        }

        const accessToken = sessionStorage.getItem('accessToken');
        const scenarioId = sessionStorage.getItem('scenarioId');

        if (lineIdToken && !accessToken) {
            dispatch(exchangeLineToken(lineIdToken, '/setting'));
        }

        if (accessToken && scenarioId) {
            dispatch(fetchPreSettingCondition());
        }
    }, [lineIdToken, dispatch]);

    return (
        <PageProvider>
            <Title>การตั้งค่า</Title>
            <div style={{ marginTop: '16px' }} />
            <div style={{ width: '100%', height: '40px', display: 'flex', alignItems: 'center' }}>
                <span style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontFamily: Fonts.Krungsri_Bold,
                }}>
                    เกี่ยวกับบริการ Krungsri Simple
                </span>
            </div>
            <SettingOption
                description="จัดการผลิตภัณฑ์"
                onClick={() => goToNextPage('confirm-account', 'manage_account')}
                hideBottomBorder={true}
            />
            <div>
                <SettingOption
                    description="จัดการความยินยอม"
                    onClick={() => goToNextPage('manage-consent', 'manage_consent')}
                    disabled={!isFillConsentAllowed}
                    hideBottomBorder={true}
                />
            </div>
            <SettingOption
                description="จัดการความสนใจของคุณ"
                onClick={() => goDirectNextPage('setting-survey', 'manage_survey')}
                hideBottomBorder={true}
            />
            <SettingOption
                description="ยกเลิกบริการ Krungsri Simple"
                onClick={() => goToNextPage('unbinding', 'unbinding')}
            />
        </PageProvider>
    );
}
