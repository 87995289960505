import liff from "@line/liff";
import { useState, useEffect } from "react";
import { hidePopupAlert, hideSpinner, showPopupAlert, showSpinner } from "../components/common/AppProvider";
import { config } from "../env";

export const useLineAuth = () => {
  const [uuid, setUuid] = useState('');
  const [lineIdToken, setToken] = useState('');

  const showErrorPopup = (message: string) => {
    showPopupAlert({
      title: 'Error',
      description: message,
      iconType: 2,
      ButtonText: 'Close',
      ButtonClick: () =>
        hidePopupAlert(),
    });
  };

  useEffect(() => {
    const fetchTokenAndProfile = async () => {
      showSpinner()
      const liffId = config.REACT_APP_LIFF_ID;

      if (!liffId) {
        showErrorPopup('LIFF ID is not provided. Please check the configuration.');
        return;
      }

      try {
        await liff.init({ liffId });

        const getTokenWithRetry = async (retries = 3) => {
          let idToken = liff.getIDToken();
          let decodedIDToken = liff.getDecodedIDToken();

          while ((!idToken || !decodedIDToken) && retries > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            idToken = liff.getIDToken();
            decodedIDToken = liff.getDecodedIDToken();
            retries--;
          }

          return { idToken, decodedIDToken };
        };

        const { idToken, decodedIDToken } = await getTokenWithRetry();

        if (idToken) {
          setToken(idToken);
        } else {
          showErrorPopup('line token is not available. Please try again.');
        }

        if (decodedIDToken?.sub) {
          setUuid(decodedIDToken.sub);
        } else {
          showErrorPopup('line uuid is not available. Please try again.');
        }
      } catch (error) {
        showErrorPopup(`Failed to initialize LIFF: ${error}`);
      } finally {
        hideSpinner();
      }
    };

    fetchTokenAndProfile();
  }, []);

  return { uuid, lineIdToken };
};
