import React, { useEffect, useRef } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ScreenAlert from '../../../components/common/ScreenAlert';
import { useLocation } from 'react-router-dom';
import { pushEvent6 } from '../../../utils/dataLayers/before_register_layer';
import { errorMessages } from '../../../utils/errorCode';
import { logEvent } from '../../../utils/dataLayers/analytics';

const LockedPage: React.FC = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const headerParams = params.get("header")
    const iconParams = params.get("iconSources")
    const titleParams = params.get("title")
    const descParams = params.get("desc")
    const buttonParams = params.get("buttonText")
    const timeParams = params.get("time")
    const onCountdownEndParam = params.get("onCountdownEnd")

    const header = headerParams? headerParams : '';
    const iconSources = iconParams? iconParams : '';
    const title = titleParams? titleParams : '';
    const desc = descParams? descParams : '';
    const buttonText = buttonParams? buttonParams : '';
    const countdownDuration = timeParams ? Number(timeParams) : 3600;
    const onCountdownEnd = onCountdownEndParam? onCountdownEndParam : '';

    const effectRan = useRef(false);
    const flow = sessionStorage.getItem('flow');


    useEffect(() => {
        if (!effectRan.current) {
            switch (title) {
                case errorMessages['LKS0603'].title:
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_service_otp_error_pop_up',
                        action: 'otp_error',
                        label: 'verified_lock_1h',
                    })
                    break;
                case errorMessages['LKS0202'].title:
                    if (flow == 'register') {
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_otp_error_pop_up',
                            action: 'otp_error',
                            label: 'otp_suspended',
                        })
                        break;
                    } else {
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_service_otp_error_pop_up',
                            action: 'otp_error',
                            label: 'otp_suspended',
                        })
                        break;
                    }
                case errorMessages['LKS0406'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_register',
                            action: 'profile_lock',
                            label: '1_hour',
                        })
                        break;
                default:
                    pushEvent6();
                    break;
            }
            effectRan.current = true;
        }
    }, []);

    return (
        <PageProvider>
            <ScreenAlert header={header} iconSources={iconSources} title={title} 
            desc={desc} buttonText={buttonText} countdownDuration={countdownDuration} onCountdownEnd={onCountdownEnd}/>
        </PageProvider>
    );
};

export default LockedPage;
