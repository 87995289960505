import { Description } from './Texts';
import Colors from '../../assets/styles/Colors';

interface SettingOptionProps {
    description: string;
    onClick: () => void;
    disabled?: boolean;
    hideBottomBorder?: boolean;
}

const SettingOption = ({ description, onClick, disabled, hideBottomBorder }: SettingOptionProps) => (
    <div 
        style={{
            padding: '8px 0 8px 16px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            cursor: disabled ? 'not-allowed' : 'pointer', 
            borderBottom: hideBottomBorder ? 'none' : '1px solid #E8E8E8',
            borderTop: '1px solid #E8E8E8',
        }}
        onClick={disabled ? undefined : onClick} 
    >
        <div style={{ 
            width: '100%'
        }}>
            <Description>
                <span style={{color: disabled ? Colors.GREY : ''}}>
                    {description}
                </span>
            </Description>
        </div>
        <img
            style={{opacity: disabled ? 0.5 : 1}}
            src={'/svg/chevronRight.svg'}
            alt="navigate"
        />
    </div>
);

export default SettingOption;
