export const purpose = (flow: string) => {
    switch (flow) {
        case 'unbinding':
            return 'DEREGISTRATION';
        case 'select-statement':
            return 'STATEMENT_REQUEST';
        case 'manage-consent':
            return 'CONSENT_MANAGEMENT';
        case 'confirm-account':
            return 'ACCOUNT_MANAGEMENT';
        default:
            return 'OTHER';
    }
}

export const x_purpose = (flow: string) => {
    switch (flow) {
        case 'register':
            return 'Register';
        case 'unbinding':
            return 'Unregister';
        case 'select-statement':
            return 'Request Statement';
        case 'manage-consent':
            return 'Manage Consent';
        case 'confirm-account':
            return 'Manage Account';
        default:
            return 'OTHER';
    }
}