import { logEvent } from "./analytics";

export const TRACK_EVENT = 'track_event';

// CATEGORY
export const CATEGORY_OTP = 'line_krungsri_simple_otp';
export const CATEGORY_OTP_POPUP = 'line_krungsri_simple_otp_pop_up';
export const CATEGORY_OTP_ERROR = 'line_krungsri_simple_otp_error_pop_up';

// ACTION
export const ACTION_CLICK_BUTTON = 'click_button';
export const ACTION_OTP_POPUP = 'otp_popup';
export const ACTION_OTP_ERROR = 'otp_error';

// LABEL
export const LABEL_AGREE = 'agree';

export const pushClickButtonLayer = (eventLabel: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_OTP,
    action: ACTION_CLICK_BUTTON,
    label: eventLabel,
  });
};

export const pushOkButton = (errorReason: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_OTP_ERROR,
    action: ACTION_CLICK_BUTTON,
    label: LABEL_AGREE,
    error: errorReason,
  });
};

export const pushOtpErrorPage = (errorReason: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_OTP_ERROR,
    action: ACTION_OTP_ERROR,
    label: errorReason,
  });
};
