import React, { ReactNode, CSSProperties } from 'react';
import Colors from '../../assets/styles/Colors';

interface BottomSpanProps {
  children: ReactNode;
  style?: CSSProperties; // Optional style prop
}

const BottomSpan: React.FC<BottomSpanProps> = ({ children, style }) => {
  return (
    <footer style={{
      alignItems: 'center',
      backgroundColor: Colors.WHITE,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'center',
      paddingBottom: '16px',
      position: 'fixed',
      ...style,
    }}>
      {children}
    </footer>
  );
};

export default BottomSpan;
