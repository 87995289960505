export const Fonts = {
    Krungsri_Light: "Krungsri_Light",
    Krungsri_Medium: "Krungsri_Medium",
    Krungsri_Bold: "Krungsri_Bold",
    Thongterm_Medium: "Thongterm_Medium",
    Thongterm_Bold: "Thongterm_Bold",
}

export const FontSize = {
    Tiny: "12px",
    Small: "14px",
    Medium: "16px",
    Large: "18px",
    Big: "20px",
    Huge: "22px",
    Massive: "24px",
    Title: "26px"
}