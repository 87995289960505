import ToggleSwitch from "./ToggleSwitch";
import { Description } from '../common/Texts';
import { safeNavigate } from "../../utils/navigation";

type AccountInfoProps = {
  accountNo: string;
  type: string;
  isNotificationEnabled: boolean;
  accountStatus: string;
  isKMA: boolean;
  onToggle: () => void
}

export default function AccountInfo({ accountNo, type, isNotificationEnabled, accountStatus, isKMA, onToggle }: AccountInfoProps) {
  const goToNextPage = () => {
    safeNavigate(`/add-account/${accountNo}`, { accountStatus: accountStatus });
  };

  function maskAccountNumber(accountNumber: string): string {
    if (accountNumber.length !== 10) {
      throw new Error('Account number must be exactly 10 digits');
    }
    return `XXX-${accountNumber[3]}-${accountNumber.slice(4, 9)}-X`;
  }

  function matchTypeAccount(type: string): string {
    const typeToRoleMap: { [key: string]: string } = {
      'SVNG': 'บัญชีออมทรัพย์',
      'CURR': 'บัญชีกระแสรายวัน',
      'TIME': 'บัญชีเงินฝากประจำ',
    };

    return typeToRoleMap[type] || 'เงินฝากออมทรัพย์';
  }

  const accountIcon = isKMA ? '/svg/passbook.svg' : '/svg/accBook.svg'

  return (
    <div style={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #E8E8E8',
    }}>
      <img
        src={accountIcon}
        alt="ks-passbook"
        style={{
          width: '40px',
          height: '40px',
        }}
      />
      <div style={{
        flexGrow: 1,
        paddingLeft: '10px',
        paddingRight: '13px',
        paddingBottom: '8px',
        paddingTop: '8px',
      }}>

        <div onClick={goToNextPage} style={{ display: 'flex', alignItems: 'center' }}>
          <Description style={{ marginRight: '8px' }}>
            {matchTypeAccount(type)}
          </Description>
          <img
            src={'/svg/chevronRight.svg'}
            alt="switch"
          />
        </div>
        <Description>{maskAccountNumber(accountNo)}</Description>
        {accountStatus === 'Inactive' && <Description>{'กรุณาติดต่อสาขา เพื่อเปิดใช้งานบัญชี'}</Description>}
      </div>
      {
        accountStatus === 'Active' ?
          <ToggleSwitch isToggled={isNotificationEnabled} onToggle={onToggle} /> :
          <div style={{ fontSize: '16px', fontFamily: 'Thongterm_Medium', color: "#5A5A5A" }}>
            {accountStatus}
          </div>
      }

    </div>
  );
}
