interface ErrorDetail {
  title: string;
  description: string;
  iconType?: number;
  isScreenAlert?: boolean;
  primaryButtonText?: string;
}

export const errorMessages: Record<string, ErrorDetail> = {
  LKS0101: {
    title: "ระบบไม่สามารถใช้งานได้ขณะนี้",
    description: "กรุณาทำรายการใหม่ภายหลัง",
    iconType: 1,
    isScreenAlert: false,
  },
  LKS0102: {
    title: "ระบบไม่สามารถใช้งานได้ขณะนี้",
    description: "การเชื่อมต่ออินเทอร์เน็ตขัดข้อง",
  },
  'LKS0402': {
    title: 'ไม่พบข้อมูลลูกค้า',
    description: `กรุณาตรวจสอบข้อมูลและลองอีกครั้ง`
  },
  LKS0401: {
    title: "ระบบไม่สามารถใช้งานได้ขณะนี้",
    description: "กรุณาทำรายการใหม่ภายหลัง",
  },
  LKS0403: {
    title: "กรอกข้อมูลไม่ถูกต้องเกินกำหนด",
    description: `ธนาคารไม่พบข้อมูลลูกค้าในระบบ\nกรุณาสมัครบริการอีกครั้งในภายหลัง`,
    iconType: 2,
  },
  'LKS0406': {
    title: 'ไม่สามารถสมัครบริการได้ในขณะนี้',
    description: `คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาสมัครบริการอีกครั้งในอีก`,
    isScreenAlert: true
  },
  'LKS0407': {
    title: 'การสมัครบริการของคุณถูกระงับ',
    description: 'คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาติดต่อสาขาธนาคารกรุงศรี ก่อนสมัครบริการอีกครั้ง',
    iconType: 2,
    isScreenAlert: true,
  },
  LKS0405: {
    title: "สมัครต่อด้วยบัญชี LINE นี้หรือไม่",
    description: `หากคุณยืนยันที่จะสมัครต่อ\nบริการ Krungsri Simple ที่คุณเคยสมัคร\nด้วยบัญชี LINE อื่นจะถูกยกเลิก`,
    primaryButtonText: "สมัครต่อ",
  },
  LKS0301: {
    title: "ระบบไม่สามารถใช้งานได้ขณะนี้",
    description: "กรุณาทำรายการใหม่ภายหลัง",
  },
  'LKS0302': {
    title: 'คุณไม่ได้สมัครใช้ krungsri app',
    description: 'คุณต้องการยืนยันตัวตนด้วยรหัสผ่านครั้งเดียว (OTP) เพื่อทำรายการต่อหรือไม่'
  },
  'LKS0303': {
    title: 'ไม่มีบัญชีที่สมัครบริการได้',
    description: 'คุณไม่มีบัญชีใน krungsri app ที่ตรงตามเงื่อนไขการใช้บริการ Krungsri Simple'
  },
  LKS0501: {
    title: "ระบบไม่สามารถใช้งานได้ขณะนี้",
    description: "กรุณาทำรายการใหม่ภายหลัง",
  },
  'LKS0502': {
    title: 'ไม่สามารถเพิ่มบัญชีได้',
    description: 'คุณมีบัญชีที่รับบริการครบ 15 รายการแล้ว\nหากต้องการเพิ่ม\nกรุณาลบบัญชีเดิมก่อน'
  },
  LKS0503: {
    title: "ไม่พบเลขที่บัญชี",
    description: `เลขที่บัญชีนี้ไม่ตรงกับข้อมูลลูกค้าหรือไม่มีในระบบ\nกรุณาลองอีกครั้ง`,
  },
  LKS0504: {
    title: "ประเภทบัญชีไม่รองรับ",
    description:
      "คุณสามารถใช้บริการนี้กับบัญชีเงินฝากออมทรัพย์\nฝากประจำ และกระแสรายวัน\nเท่านั้น กรุณาลองอีกครั้ง",
  },
  LKS0505: {
    title: "มีบัญชีนี้ในระบบแล้ว",
    description: "กรุณากรอกเลขที่บัญชีอื่น",
  },
  'LKS0506': {
    title: 'บัญชีไม่พร้อมใช้งาน',
    description: `กรุณาติดต่อสาขาธนาคารกรุงศรี เพื่อเปิดใช้งานบัญชีอีกครั้ง`
  },
  LKS0201: {
    title: "ระบบไม่สามารถใช้งานได้ขณะนี้",
    description: "กรุณาทำรายการใหม่ภายหลัง",
  },
  LKS0202: {
    title: "ไม่สามารถส่งรหัส OTP ได้ในขณะนี้",
    description: `เนื่องจากขอรหัส OTP เกินจำนวนครั้งที่กำหนด\nกรุณาลองอีกครั้งในอีก`,
  },
  LKS0203: {
    title: "รหัส OTP หมดอายุ",
    description: "กรุณาขอรหัส OTP ใหม่",
  },
  LKS0204: {
    title: "กรอก OTP ไม่ถูกต้องเกินกำหนด",
    description: "กรุณาขอรหัส OTP ใหม่ และลองกรอกอีกครั้ง",
  },
  LKS0205: {
    title: "รหัส OTP ไม่ถูกต้อง",
    description: "กรุณากรอกรหัส OTP อีกครั้ง หรือขอรหัสใหม่",
  },
  LKS0601: {
    title: "ระบบไม่สามารถใช้งานได้ขณะนี้",
    description: "กรุณาทำรายการใหม่ภายหลัง",
  },
  LKS0602: {
    title: "กรอกข้อมูลไม่ถูกต้องเกินกำหนด",
    description:
      "ธนาคารไม่พบข้อมูลลูกค้าในระบบ กรุณาทำรายการอีกครั้งในภายหลัง",
  },
  'LKS0603': {
    title: 'ไม่สามารถยืนยันตัวตนได้ในขณะนี้',
    description: `คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาทำรายการอีกครั้งในอีก`
  },
  'LKS0604': {
    title: 'ไม่สามารถยืนยันตัวตนได้',
    description: 'คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาติดต่อสาขาธนาคารกรุงศรี ก่อนใช้บริการอีกครั้ง'
  },
  'LKS0605': {
    title: 'ไม่พบข้อมูลลูกค้า',
    description: 'กรุณาตรวจสอบข้อมูลและลองอีกครั้ง'
  },
  'LKS0606': {
    title: 'ไม่พบข้อมูลลูกค้า',
    description: 'กรุณาตรวจสอบข้อมูลและลองอีกครั้ง'
  }
};
