import React from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import { Description, Title } from '../../../components/common/Texts';
import Button from '../../../components/common/Button';
import { safeNavigate } from '../../../utils/navigation';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';
import { logEvent } from '../../../utils/dataLayers/analytics';

const BindingConsentPage: React.FC = () => {
    const goToNextPage = () => {
        safeNavigate("/binding-consent-form");
        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_account',
            action: 'click_button',
            label: 'next'
        })
    }

    return (
        <PageProvider>
            <div style={{ marginBottom: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/svg/bindingConsent.svg" style={{ height: 160, width: 160, }} />
            </div>
            <Title style={{ marginBottom: '8px', textAlign: 'center', fontSize: '20px', lineHeight: '30px', color: Colors.TEXT_BODY_PRIMARY }}>
                แบบฟอร์มขอความยินยอมเกี่ยวกับข้อมูล
            </Title>
            <Description style={{ marginBottom: '24px', fontFamily: Fonts.Krungsri_Light, fontSize: '14px', lineHeight: '22px', color: Colors.TEXT_BODY_PRIMARY }}>
                ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน) และบริษัทในกลุ่มธุรกิจ ทางการเงินของธนาคารกรุงศรีอยุธยา จำกัด (มหาชน) (รวมเรียกว่า “กลุ่มกรุงศรี”*) ให้ความสำคัญเกี่ยวกับความ
                ปลอดภัยของข้อมูลของคุณและเพื่อให้คุณมั่นใจว่ากลุ่มกรุงศรี มีความมุ่งมั่นที่จะให้ความคุ้มครองและดำเนินการด้วยความ รับผิดชอบต่อการเก็บรวบรวม ใช้ เปิดเผย และโอนข้อมูลของ คุณ
                กลุ่มกรุงศรีจึงขอความยินยอมจากคุณ ดังนี้
            </Description>
            <Description style={{ fontFamily: Fonts.Krungsri_Bold, fontSize: '14px', lineHeight: '24px', color: Colors.TEXT_BODY_PRIMARY }}>
                โปรดอ่านและทำเครื่องหมายเพื่อพิจารณาให้ความยินยอม หรือ ไม่ให้ความยินยอม
            </Description>
            <ButtomSpan>
                <Button text={'ถัดไป'} onClick={goToNextPage} type={0} />
            </ButtomSpan>
        </PageProvider>
    );
};

export default BindingConsentPage;
