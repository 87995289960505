import React from 'react';
import { useLineAuth } from '../../../hooks/useLineAuth';

const LandingPage: React.FC = () => {

    const { lineIdToken } = useLineAuth();

    return (
        <div style={{ backgroundColor: 'white', height: '100vh', width: '100vw' }}>
        </div>
    );
};

export default LandingPage;
