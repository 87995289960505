import React, { useEffect, useRef } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import { logEvent } from '../../../utils/dataLayers/analytics';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';
import liff from '@line/liff';
import Button from '../../../components/common/Button';
import BottomSpan from '../../../components/layout/BottomSpan';

const SuccessPage: React.FC = () => {
    const pushEvent = useRef(false);
    
    useEffect(() => {
        if (!pushEvent.current) {
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_request_statement',
                action: 'request_statement_popup',
                label: 'request_statement_completed',
            })
            pushEvent.current = true;
        }
    }, []);

    return (
        <PageProvider>
            <div style={{
                margin: '0 auto',
                textAlign: 'center',
                paddingTop: '17vh',
            }}>
                <img src={'/svg/valid.svg'} alt="Popup Icon" style={{
                    width: '88px',
                    height: '88px',
                    marginBottom: '4vh',
                }} />
                <div style={{ color: Colors.MAIN_TEXT_COLOR, fontFamily: Fonts.Krungsri_Bold, fontSize: '24px', lineHeight: '36px', marginBottom: '8px' }}>
                    {'ขอ Statement สำเร็จ'}
                </div>
                <div style={{ color: Colors.PRIMARY_TEXT_COLOR, fontFamily: Fonts.Thongterm_Medium, fontSize: '16px', lineHeight: '29px', whiteSpace: 'pre-line', }}>
                    {`ธนาคารได้รับคำขอของคุณเรียบร้อยแล้ว\nและจะส่งรายการเดินบัญชี\nให้คุณภายใน 1 ชั่วโมง`}
                </div>
            </div>
            <BottomSpan>
                <Button text={'กลับสู่หน้า LINE'} onClick={() => liff.closeWindow()} type={0} />
            </BottomSpan>
        </PageProvider>
    );
};

export default SuccessPage;
