const Colors = {
    BACKGROUND_DISABLE: '#F0EFEE',
    MAIN_TEXT_COLOR: '#222222',
    PRIMARY_KS_YELLOW: '#FFD400',
    PRIMARY_TEXT_COLOR: '#5A5A5A',
    TEXT_BODY_PRIMARY: '#202020',
    TEXT_BODY_SECONDARY: '#433938',
    SECONDARY_BROWN: '#554242',
    SECONDARY_TEXT_COLOR: '#949494',
    STROKE_NORMAL: '#E8E8E8',
    
    WHITE: '#FFF',
    BLACK: '#000',
    GREY: '#BBBBBB',
    LIGHT_GREY: '#D7D7D7',
    ORANGE: '#F76F00',
    RED: '#D00000'
};

export default Colors;