import React from 'react';
import Colors from '../../../assets/styles/Colors';
import { Description, Title } from '../Texts';
import Button from '../Button';
import { customAlert } from '../../../utils/customAlert';

interface PopupAlertProps {
    logMessage?: string;
    isVisible?: boolean;
    iconType?: number;
    title: string;
    description: string;
    ButtonClick: () => void;
}

const iconSources: { [key: number]: string } = {
    0: '',
    1: '/svg/alert.svg',
    2: '/svg/suspend.svg',
};

const PopupAlert: React.FC<PopupAlertProps> = ({
    logMessage,
    isVisible = true,
    iconType = 1,
    title,
    description,
    ButtonClick,
}) => {
    if (!isVisible) return null;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(89, 89, 89, 0.5)',
            zIndex: 1000,
        }}>
            <div style={{
                backgroundColor: Colors.WHITE,
                padding: '40px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '4px'
            }}>
                <div onClick={() => customAlert(JSON.stringify(logMessage))}>
                    {iconSources[iconType] && <img src={iconSources[iconType]} alt="Popup Icon" style={{
                        width: '88px',
                        height: '88px',
                        marginBottom: '40px',
                    }} />}
                </div>

                <Title style={{ fontSize: '24px', lineHeight: '36px', marginBottom: '8px', padding: '0px 16px' }}>{title}</Title>
                <Description style={{ whiteSpace: 'pre-line', marginBottom: '40px', padding: '0px 16px' }}>{description}</Description>
                <Button text="ตกลง" onClick={ButtonClick} type={0} />
            </div>
        </div>
    );
};

export default PopupAlert;
