import { logEvent } from "./analytics";

export const TRACK_EVENT = "track_event";

export const CATEGORY_ACCOUNT_ERROR_POPUP =
  "line_krungsri_manage_consent_error_pop_up";

export const ACTION_ADD_MORE_ACCOUNT = "add_more_account_error";
export const ACTION_CLICK_BUTTON = "click_button";

export const pushEvent10 = (errorReason: string) => {
    logEvent({
      event: TRACK_EVENT,
      category: CATEGORY_ACCOUNT_ERROR_POPUP,
      action: ACTION_ADD_MORE_ACCOUNT,
      label: errorReason,
    });
  };

export const pushEvent11 = (errorReason: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_ACCOUNT_ERROR_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'agree',
    error: errorReason,
  });
};
