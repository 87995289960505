import React, { useEffect, useRef } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import { pushSurveyComplete } from '../../../utils/dataLayers/survay_layer';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';
import BottomSpan from '../../../components/layout/BottomSpan';
import Button from '../../../components/common/Button';
import liff from '@line/liff';

const SuccessPage: React.FC = () => {
    const effectRan = useRef(false);

    const flow = sessionStorage.getItem('flow');

    useEffect(() => {
        if (effectRan.current) return;
            if(flow === 'select-statement' || flow === 'register') {
                pushSurveyComplete();
            }

        effectRan.current = true;
    }, [flow]);

    return (
        <PageProvider>
            <div style={{
                margin: '0 auto',
                textAlign: 'center',
                paddingTop: '23vh',
            }}>
                <img src={'/svg/valid.svg'} alt="Popup Icon" style={{
                    width: '88px',
                    height: '88px',
                    marginBottom: '4vh',
                }} />
                <div style={{ color: Colors.MAIN_TEXT_COLOR, fontFamily: Fonts.Krungsri_Bold, fontSize: '24px', lineHeight: '36px' }}>
                    {'ขอบคุณสำหรับข้อมูล'}
                </div>
            </div>
            <BottomSpan>
                <Button text={'เริ่มใช้บริการ Krungsri Simple'} onClick={() => liff.closeWindow()} type={0} />
            </BottomSpan>
        </PageProvider>
    );
};

export default SuccessPage;
