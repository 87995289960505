import React, { useState, useEffect } from "react";
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title } from '../../../components/common/Texts';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserInfo, saveSensitiveData, preRegisterKMA, preRegisterOTP, setAuthenticationReference, resetSensitiveData, verifyDeepLink } from '../../../actions/registerAction';
import { registerSelector } from '../../../reducers/registerReducer'
import { RootState } from '../../../store/store'
import { getOSByPlatform } from '../../../utils/getOSByPlatform'
import CustomInput from "../../../components/common/CustomInput";
import { formatIdCard, formatMobileNumber, formatReferral } from "../../../utils/formatInput";
import ReCAPTCHAMessage from '../components/ReCAPTCHAMessage';
import { safeNavigate } from "../../../utils/navigation";
import { pushEvent11, pushEvent14, pushEvent2, pushEvent3 } from '../../../utils/dataLayers/before_register_layer';
import RecaptchaModal from "./RecapthaModal";
import { showPopupVerify, hidePopupVerify, hideSpinner } from "../../../components/common/AppProvider";
import { errorMessages } from "../../../utils/errorCode";
import { useLocation } from "react-router-dom";
import { logEvent } from "../../../utils/dataLayers/analytics";
import { isDevelop } from "../../../utils/getEnv";

const InfoPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const captchaParams = params.get("isValidateCaptcha");

  const validateCaptcha = captchaParams ? captchaParams : false;
  const { idCard, mobileNumber, referral, authenticationApplicationUri, authenticationCount, reCaptchaToken, otpExpirationTime } = useSelector((state: RootState) => registerSelector(state));
  const [cid, setCID] = useState<string>(formatIdCard(idCard));
  const [captcha, setCaptcha] = useState(validateCaptcha === 'true' && !reCaptchaToken ? true : false);
  const [token, setToken] = useState(reCaptchaToken);
  const [phoneNumber, setPhoneNumber] = useState<string>(formatMobileNumber(mobileNumber));
  const isHaveKMA = JSON.parse(sessionStorage.getItem('isHaveKMA') || 'false');
  const authRef = sessionStorage.getItem('authRef');
  const [recAcc, setRecAcc] = useState<string>(referral);
  const [isAllValid, setIsAllValid] = useState(false);
  const [isKeyboard, setIsKeyboard] = useState<boolean>(false);
  const [isFirstTimeIdCard, setIsFirstTimeIdCard] = useState(
    () => JSON.parse(sessionStorage.getItem('isFirstTimeIdCard') || 'true')
  );
  const [isFirstTimeMobileNo, setIsFirstTimeMobileNo] = useState(
    () => JSON.parse(sessionStorage.getItem('isFirstTimeMobileNo') || 'true')
  );

  const adjustHeight = () => {
    const height = window.innerHeight;
    if (height < 600) {
      setIsKeyboard(true)
    } else {
      setIsKeyboard(false)
    }
  };

  useEffect(() => {
    window.addEventListener('resize', adjustHeight);
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  useEffect(() => {
    checkIsCanGoNext();
  }, []);

  useEffect(() => {
    if (!(idCard && mobileNumber)) {
      setIsAllValid(false)
    }
    setCID(formatIdCard(idCard));
    setPhoneNumber(formatMobileNumber(mobileNumber));
    setRecAcc(formatReferral(referral));
  }, [idCard, mobileNumber, referral]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('App is in the foreground');
      } else {
        console.log('App is in the background');
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (authenticationApplicationUri) {
      window.location.href = authenticationApplicationUri;
      const timeoutId = setTimeout(() => {
        if (document.visibilityState === 'visible') {
          showPopupVerify({
            title: 'ไม่พบ krungsri app บนอุปกรณ์นี้',
            description: `คุณต้องการยืนยันตัวตนด้วยรหัสผ่านครั้งเดียว (OTP) \n เพื่อทำรายการต่อหรือไม่`,
            primaryButtonText: 'ยืนยันด้วย​ OTP',
            primaryButtonClick: () => {
              dispatch(setAuthenticationReference('', '', '', 0));
              sessionStorage.setItem('isHaveKMA', JSON.stringify(false));
              dispatch(saveUserInfo(cid.replace(/-/g, ''), phoneNumber.replace(/-/g, ''), recAcc, getOSByPlatform(), otpExpirationTime, token, true))
              hidePopupVerify()
              hideSpinner()
              pushEvent14('not_found_kma_app');
            },
            secondaryButtonClick: () => {
              hidePopupVerify();
              hideSpinner();
              pushEvent11('not_found_kma_app');
            }
          });
        } else {
          dispatch(verifyDeepLink())
        }
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [authenticationCount]);

  const goToNextPage = () => {
    const cidReplaceDash = cid.replace(/-/g, '')
    const phoneNumberReplaceDash = phoneNumber.replace(/-/g, '')
    if (cidReplaceDash !== idCard || phoneNumberReplaceDash !== mobileNumber) {
      dispatch(saveUserInfo(cidReplaceDash, phoneNumberReplaceDash, recAcc, getOSByPlatform(), '', token))
      logEvent({
        event: 'track_event',
        category: 'line_krungsri_simple_register',
        action: 'click_button',
        label: 'next_info',
      })
    } else {
      dispatch(saveUserInfo(cidReplaceDash, phoneNumberReplaceDash, recAcc, getOSByPlatform(), otpExpirationTime, token))
      logEvent({
        event: 'track_event',
        category: 'line_krungsri_simple_register',
        action: 'click_button',
        label: 'next_info',
      })
    }
  };

  const goToPreviousPage = () => {
    setCID('');
    setPhoneNumber('');
    setRecAcc('');
    sessionStorage.setItem('isFirstTimeIdCard', JSON.stringify(true));
    sessionStorage.setItem('isFirstTimeMobileNo', JSON.stringify(true));
    dispatch(resetSensitiveData())
    dispatch(setAuthenticationReference('', '', '', 0));
    safeNavigate('/verify');
    pushEvent3();
  };

  const validateIdCard = (value: string) => {
    if (isFirstTimeIdCard) {
      return null;
    }
    if (!value) return 'กรุณากรอกหมายเลขบัตรประชาชน';

    const idCard = value.replace(/\D/g, '');

    if (idCard.replace(/\D/g, '').length !== 13) return 'กรุณากรอกหมายเลขบัตรประชาชน 13 หลักให้ถูกต้อง';

    let sum = 0;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(idCard.charAt(i)) * (13 - i);
    }
    if (!isDevelop) {
      if ((11 - sum % 11) % 10 !== parseInt(idCard.charAt(12))) return 'กรุณากรอกหมายเลขบัตรประชาชน 13 หลักให้ถูกต้อง';
    }

    return null;
  };

  const validateMobileNumber = (value: string) => {
    if (isFirstTimeMobileNo) {
      return null;
    }
    if (!value) return 'กรุณากรอกเบอร์มือถือ';
    if (value.replace(/\D/g, '').length !== 10) return 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้อง';
    if (value.charAt(0) !== '0') return 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้อง';
    return null;
  };

  const checkIsCanGoNext = () => {
    const idCardError = validateIdCard(cid);
    const mobileNumberError = validateMobileNumber(phoneNumber);

    if (idCardError && mobileNumberError) {
      let combinedErrorReason = '';

      combinedErrorReason = `${idCardError === "กรุณากรอกหมายเลขบัตรประชาชน" ? 'not_filled' : 'not_correct'} | ${mobileNumberError === "กรุณากรอกเบอร์มือถือ" ? 'not_filled' : 'not_correct'}`;

      logEvent({
        event: 'track_event',
        category: 'line_krungsri_simple_register',
        action: 'field_error',
        label: 'id_card | phone_number',
        error: combinedErrorReason,
      })
    } else if (idCardError) {
      logEvent({
        event: 'track_event',
        category: 'line_krungsri_simple_register',
        action: 'field_error',
        label: 'id_card',
        error: idCardError === "กรุณากรอกหมายเลขบัตรประชาชน" ? 'not_filled' : 'not_correct',
      })
    } else if (mobileNumberError) {
      logEvent({
        event: 'track_event',
        category: 'line_krungsri_simple_register',
        action: 'field_error',
        label: 'phone_number',
        error: mobileNumberError === "กรุณากรอกเบอร์มือถือ" ? 'not_filled' : 'not_correct',
      })
    }

    setIsAllValid(!idCardError && !mobileNumberError && !isFirstTimeIdCard && !isFirstTimeMobileNo);
  };


  const onFocusIdCard = () => {
    setIsFirstTimeIdCard(false);
    sessionStorage.setItem('isFirstTimeIdCard', JSON.stringify(false));
  };

  const onFocusMobileNo = () => {
    setIsFirstTimeMobileNo(false);
    sessionStorage.setItem('isFirstTimeMobileNo', JSON.stringify(false));
  };

  const inputFields = [
    {
      title: 'หมายเลขบัตรประชาชน',
      placeholder: 'ตัวอย่าง: 1234567890123',
      value: cid,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCID(e.target.value),
      type: 'tel' as 'tel',
      validate: validateIdCard,
      format: formatIdCard,
      onBlur: checkIsCanGoNext,
      onFocus: onFocusIdCard,
    },
    {
      title: 'เบอร์มือถือ',
      subtitle: 'เบอร์ที่เคยให้กับธนาคารกรุงศรี',
      placeholder: 'ตัวอย่าง: 0881234567',
      value: phoneNumber,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value),
      type: 'tel' as 'tel',
      validate: validateMobileNumber,
      format: formatMobileNumber,
      onBlur: checkIsCanGoNext,
      onFocus: onFocusMobileNo,
    },
    {
      title: 'รหัสผู้แนะนำ (ถ้ามี)',
      placeholder: 'รหัสผู้แนะนำบริการ',
      value: recAcc,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setRecAcc(e.target.value),
      type: 'text' as 'text',
      format: formatReferral,
      onBlur: checkIsCanGoNext,
    },
  ];

  return (
    <PageProvider>
      <Title style={{ marginBottom: 8 }}>ข้อมูลผู้สมัคร</Title>
      {inputFields.map((field, index) => (
        <CustomInput
          key={index}
          title={field.title}
          subtitle={field.subtitle}
          placeholder={field.placeholder}
          value={field.value}
          onChange={field.onChange}
          type={field.type}
          validate={field.validate}
          format={field.format}
          onBlur={field.onBlur}
          onFocus={field.onFocus}
        />
      ))}
      <ButtomSpan>
        {!isKeyboard && (
          <div>
            <Button text={'ถัดไป'} type={isAllValid ? 0 : 1} onClick={goToNextPage} />
            <div style={{ marginBottom: 24 }} />
            <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
            <ReCAPTCHAMessage />
          </div>
        )}
      </ButtomSpan>
      {captcha && <RecaptchaModal setCaptha={setCaptcha} setToken={setToken} />}
    </PageProvider>
  );
};

export default InfoPage;
