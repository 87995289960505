import React from 'react';
import Colors from '../../assets/styles/Colors';
import { Fonts } from '../../assets/styles/Fonts';

type ButtonType = 0 | 1 | 2;

interface ButtonProps {
  onClick: () => void;
  text: string;
  type: ButtonType;
}

const borderType: { [key in ButtonType]: string } = {
    0: `1px solid ${Colors.WHITE}`,
    1: `1px solid ${Colors.WHITE}`,
    2: `1px solid ${Colors.STROKE_NORMAL}`,
  };

const backgroundType: { [key in ButtonType]: string } = {
  0: Colors.PRIMARY_KS_YELLOW,
  1: Colors.BACKGROUND_DISABLE,
  2: Colors.WHITE,
};

const fontFamily: { [key in ButtonType]: string } = {
  0: Fonts.Krungsri_Medium,
  1: Fonts.Krungsri_Light,
  2: Fonts.Krungsri_Light,
};

const Button: React.FC<ButtonProps> = ({ onClick, text, type }) => {
  return (
    <button
      disabled={type === 1}
      onClick={onClick}
      style={{
        background: backgroundType[type],
        borderRadius: '4px',
        border: borderType[type],
        color: Colors.PRIMARY_TEXT_COLOR,
        fontFamily: fontFamily[type],
        fontSize: '16px',
        fontStyle: 'normal',
        height: '30px',
        lineHeight: '27px',
        textAlign: 'center',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
      }}>
      {text}
    </button>
  );
};

export default Button;
