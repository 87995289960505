import React, { useState, useEffect } from 'react';
import Colors from '../../assets/styles/Colors';
import { Title, Description } from './Texts';
import Button from './Button';
import liff from '@line/liff'
import BottomSpan from '../layout/BottomSpan';
import { pushEvent7 } from '../../utils/dataLayers/before_register_layer';
import { Fonts } from '../../assets/styles/Fonts';
import { safeNavigate } from '../../utils/navigation';
import { errorMessages } from '../../utils/errorCode';
import { logEvent } from '../../utils/dataLayers/analytics';

interface ScreenAlertProps {
    header?: string;
    iconSources: string;
    title: string;
    desc?: string;
    type?: number
    buttonText: string
    countdownDuration?: number
    onCountdownEnd?: string;
}

const ScreenAlert: React.FC<ScreenAlertProps> = ({
    header = '',
    iconSources,
    title,
    desc = '',
    type = 1,
    buttonText,
    countdownDuration = 1800,
    onCountdownEnd,
}) => {

    const [timeLeft, setTimeLeft] = useState(countdownDuration);

    const closeLiffWindow = () => {
        liff.closeWindow();
        switch (title) {
            case errorMessages['LKS0406'].title:
                pushEvent7('line_krungsri_simple_error_pop_up', 'profile_lock_1h');
                break;
            case errorMessages['LKS0407'].title:
                pushEvent7('line_krungsri_simple_error_pop_up', 'profile_lock_suspended');
                break;
            case 'ไม่สามารถสมัครบริการได้':
                pushEvent7('line_krungsri_simple_error_pop_up', 'unable_to_register');
                break;
            case errorMessages['LKS0202'].title:
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_otp',
                    action: 'click_button',
                    label: 'return_to_line',
                    error: 'otp_suspended'
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    if (onCountdownEnd) {
                        safeNavigate(onCountdownEnd);
                    }
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const callPhoneNumber = () => {
        liff.openWindow({
            url: 'tel:1572', // This will open the app if the user has it installed
            external: true,
          });
    };

    // <div style={{
    //     margin: '0 auto',
    //     textAlign: 'center',
    //     paddingTop: '18vh',
    // }}>

    const renderButton = () => {
        if (type === 2) {
            return (
                <div>
                    <Button text={buttonText} onClick={callPhoneNumber} type={0} />
                    <div style={{ marginBottom: 24 }} />
                    <Button text={buttonText[1]} onClick={closeLiffWindow} type={2} />
                </div>
            );
        }
        if (type === 8) {
            return (<></>)
        }

        return <Button text={buttonText} onClick={closeLiffWindow} type={0} />;
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}>
            <div >
                {type === 2 || type === 1 || type === 4 ? (
                    <Title style={{ color: Colors.BLACK }}>
                        {header}
                    </Title>
                ) : (
                    <div style={{
                        height: '0px'
                    }}>

                    </div>
                )}
            </div>
            <div style={{
                margin: '0 auto',
                textAlign: 'center',
                paddingTop: header ? '11vh' : '23vh',
            }}>
                {iconSources && <img src={iconSources} alt="Popup Icon" style={{
                    width: '88px',
                    height: '88px',
                    marginBottom: '40px',
                }} />}
                <Title style={{ fontSize: '24px', lineHeight: '36px', marginBottom: '8px',whiteSpace: 'pre-line' }}>
                    {title}
                </Title>
                <div style={{
                    textAlign: 'center',
                    margin: '0 auto',
                    whiteSpace: 'pre-line',
                }}>

                    <span style={{ fontWeight: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                        {desc && <Description>
                            {desc}
                            {(type === 1 || type === 4) && (
                                <>
                                    <span style={{
                                        color: Colors.MAIN_TEXT_COLOR,
                                        fontFamily: Fonts.Thongterm_Bold,
                                        fontSize: '16px',
                                        lineHeight: '29px',
                                        marginLeft: '4px',
                                    }}>
                                        {formatTime(timeLeft)}
                                    </span>
                                    <span style={{
                                        color: Colors.PRIMARY_TEXT_COLOR,
                                        fontFamily: Fonts.Thongterm_Medium,
                                        fontSize: '16px',
                                        lineHeight: '29px',
                                        marginLeft: '4px',
                                    }}>
                                        นาที
                                    </span>
                                </>
                            )}
                        </Description>}
                    </span>
                </div>
            </div>

            <BottomSpan>
                {renderButton()}
            </BottomSpan>
        </div>
    );
};

export default ScreenAlert;
