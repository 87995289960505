import React, { useEffect, useMemo, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AccountInfo from '../../../components/common/AccountInfo';
import Button from '../../../components/common/Button';
import { Description, Title } from '../../../components/common/Texts';
import { registerSelector } from '../../../reducers/registerReducer';
import { RootState } from '../../../reducers/rootReducer';
import ButtomSpan from "../../../components/layout/BottomSpan"
import { setBankAccounts } from '../../../actions/registerAction';
import Colors from '../../../assets/styles/Colors';
import { hidePopupVerify, showPopupVerify } from '../../../components/common/AppProvider';
import { deleteAccount } from '../../../actions/accountAction';
import { safeNavigate } from '../../../utils/navigation';
import { pushEvent14, pushEvent15, pushEvent16, pushEvent2 } from '../../../utils/dataLayers/register_after_otp_layer';
import { title } from 'process';
import { logEvent } from '../../../utils/dataLayers/analytics';

export default function AccountDetailPage() {
    const { account_no } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const accountStatus = params.get("accountStatus")
    const dispatch = useDispatch();
    const { bankAccounts } = useSelector((state: RootState) => registerSelector(state));
    const flow = sessionStorage.getItem('flow');

    const goToPreviousPage = () => {
        safeNavigate('/confirm-account');
        if (flow === 'register') {
            pushEvent2();
        } else {
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_manage_account',
                action: 'click_button',
                label: 'back',
            })
        }
    };
    const isHaveLastAccount = bankAccounts.length === 1
    const [accounts, setAccounts] = useState(bankAccounts);
    const account = accounts.find((acc) => acc.accountNumber === account_no);

    function maskAccountNumber(accountNumber: string = ''): string {
        if (accountNumber.length !== 10) {
            return `XXX-XXX-XXX-X`;
        }
        return `XXX-${accountNumber[3]}-${accountNumber.slice(4, 9)}-X`;
    }

    function matchTypeAccount(type: string = ''): string {
        const typeToRoleMap: { [key: string]: string } = {
            'SVNG': 'บัญชีออมทรัพย์',
            'CURR': 'บัญชีกระแสรายวัน',
            'TIME': 'บัญชีเงินฝากประจำ',
        };

        return typeToRoleMap[type] || 'เงินฝากออมทรัพย์';
    }

    const removeAccount = () => {
        const accountToDelete = bankAccounts.find(account => account.accountNumber === account_no);

        if (accountToDelete && accountToDelete.accountId) {
            const accountId = accountToDelete.accountId;
            dispatch(deleteAccount(accountId));
        }
        const updatedBankAccounts = bankAccounts
            .filter(account => account.accountNumber !== account_no)
            .map(account => ({
                accountId: account.accountId || '',
                accountNumber: account.accountNumber,
                accountType: account.accountType || '',
                accountStatus: account.accountStatus || 'Active',
                isNotificationEnabled: account.isNotificationEnabled,
            }));
        
        dispatch(setBankAccounts(updatedBankAccounts));
        if (flow === 'register') {
            pushEvent15();
        }
        else {
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_manage_account_pop_up',
                action: 'click_button',
                label: 'confirm_delete_account',
            })
        }
        safeNavigate(`/confirm-account`);
    };

    const handleDelete = () => {
        if (!isHaveLastAccount) {
            if (flow === 'register') {
                pushEvent14();
            } else {
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_manage_account',
                    action: 'click_button',
                    label: 'delete_account',
                })
            }
            showPopupVerify({
                title: 'ลบบัญชีหรือไม่',
                description: `หากลบบัญชี คุณจะไม่ได้รับแจ้งเตือนเงินเข้า-ออก\nและใช้บริการอื่น ๆ ของ Krungsri Simple ได้ `,
                primaryButtonText: 'ลบบัญชี',
                primaryButtonClick: () => {
                    removeAccount()
                    hidePopupVerify();
                },
                secondaryButtonClick: () => { 
                    hidePopupVerify(); 
                    if (flow === 'register') {
                        pushEvent16();
                    } else {
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account_pop_up',
                            action: 'click_button',
                            label: 'cancel_delete_account',
                        })
                    }
                }
            })
        }
    };

    return (
        <PageProvider>
            <div>
                <Title>บัญชีเงินฝาก</Title>
                <div style={{
                    marginTop: 16,
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    <img
                        src={'/svg/accBook.svg'}
                        alt="ks-passbook"
                        style={{
                            width: '40px',
                            height: '40px',
                        }}
                    />
                    <div style={{
                        flexGrow: 1,
                        paddingLeft: '10px',
                        paddingRight: '13px',
                        paddingBottom: '8px',
                        paddingTop: '8px',
                    }}>
                        <Description>{matchTypeAccount(account?.accountType)}</Description>
                        <Description>{maskAccountNumber(account?.accountNumber)}</Description>
                        {accountStatus === 'Inactive' && <Description>{'กรุณาติดต่อสาขา เพื่อเปิดใช้งานบัญชี'}</Description>}
                    </div>
                    {
                        accountStatus !== 'Active' &&
                        <div style={{ fontSize: '16px', fontFamily: 'Thongterm_Medium', color: "#5A5A5A" }}>
                            {accountStatus}
                        </div>
                    }
                    <button
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '0 10px',
                        }}
                    >
                    </button>
                </div>
                <div style={{ height: 1, background: '#E8E8E8' }} />

                <div
                    onClick={handleDelete}
                    style={{
                        marginTop: 16,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: isHaveLastAccount ? 'not-allowed' : 'pointer',
                    }}>

                    <img
                        src={ isHaveLastAccount?'/svg/binGrey.svg':'/svg/bin.svg'}
                        alt="delete-account"
                        style={{
                            width: '16px',
                            height: '16px',
                            marginRight: 8,
                        }}
                    />

                    <Description
                        style={{ color: isHaveLastAccount ? Colors.SECONDARY_TEXT_COLOR : Colors.PRIMARY_TEXT_COLOR }}>
                        ลบออกจากบริการนี้
                    </Description>
                </div>
                {isHaveLastAccount &&
                    <Description style={{ color: Colors.SECONDARY_TEXT_COLOR }}>ไม่สามารถลบได้ เนื่องจากต้องมีบัญชีที่ผูกกับบริการ Krungsri Simple อย่างน้อย 1 บัญชี
                    </Description>
                }
                <div style={{
                    marginTop: 16,
                    marginBottom: isHaveLastAccount ? 321 : 387,
                    height: 1,
                    background: '#E8E8E8'
                }} />
            </div>
            <ButtomSpan>
                <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
            </ButtomSpan>
        </PageProvider>
    );
}
