import {
  SET_TERMS_AND_CONDITIONS,
  SET_REGISTER_REFERENCE,
  SAVE_SENSITIVE_DATA,
  SET_AUTHENTICATION_REFERENCE,
  SET_BANK_ACCOUNTS,
  SET_OTP_REFERENCE,
  SET_CONSENT_LIST,
  SET_DEFAULT_BANK_ACCOUNTS,
  RESET_OTP_REFERENCE,
  OTP_MAX_ATTEMPT_COUNT,
  RESET_OTP_ATTEMPT_COUNT,
  RESET_SENSITIVE_DATA,
  SET_VALIDATE_CAPTCHA,
  SET_HASHED_MOBILE_NO,
  RESET_STATE,
  STAMP_START_LIFF_STATEMENT,
} from '../actions/registerAction';
import {
  SET_SURVEYS_LIST,
} from '../actions/accountAction'
import { RootState } from '../store/store';
import { createSelector } from 'reselect';

interface BankAccount {
  accountId: string
  accountNumber: string;
  accountType: string;
  isNotificationEnabled: boolean;
  accountStatus: string;
}

export interface TermsAndConditionsState {
  terms: string;
  id: string;
  idCard: string;
  mobileNumber: string;
  referral: string;
  registerRef: string;
  otpRef: string;
  otpExpirationTime: string;
  isMaxAttempOtp: boolean;
  mustConfirmRegisterForReplace: boolean;
  authenticationWebUri: string,
  authenticationApplicationUri: string,
  expiryDateTime: string,
  authenticationCount: number,
  bankAccounts: BankAccount[];
  defaultBankAccounts: BankAccount[];
  survey: { uid: string; data: { id: string; name: string; type: number; }[] };
  consent: {
      consentReference: string;
      signedVersion: string;
      updatedAt: string;
      channel: string;
      entity: string; 
      id: string; 
      name: string; 
      action:string; 
      remark:string 
}[];
  reCaptchaToken: string;
  hashedMobileNumber: string;
  isStartLiff: boolean;
}

export interface TermsAndConditionsAction {
  type: string;
  payload: {
    terms: string;
    id: string;
    idCard: string;
    mobileNumber: string;
    referral: string;
    registerRef: string;
    otpRef: string;
    otpExpirationTime: string;
    isMaxAttempOtp: boolean;
    mustConfirmRegisterForReplace: boolean;
    authenticationWebUri: string,
    authenticationApplicationUri: string,
    expiryDateTime: string,
    authenticationCount: number,
    bankAccounts: BankAccount[];
    defaultBankAccounts: BankAccount[];
    survey: { uid: string; data: { id: string; name: string; type: number; }[] },
    consent: [],
    reCaptchaToken: string,
    hashedMobileNumber: string;
    isStartLiff: boolean;
  };
}

const initialState: TermsAndConditionsState = {
  terms: '',
  id: '',
  idCard: '',
  mobileNumber: '',
  otpRef: '',
  otpExpirationTime: '',
  isMaxAttempOtp: false,
  referral: '',
  registerRef: '',
  mustConfirmRegisterForReplace: false,
  authenticationWebUri: '',
  authenticationApplicationUri: '',
  expiryDateTime: '',
  authenticationCount: 0,
  bankAccounts: [],
  defaultBankAccounts: [],
  survey: {
    uid: '',
    data: [],
  },
  consent: [],
  reCaptchaToken: "",
  hashedMobileNumber: "",
  isStartLiff: true,
};


export const registerReducer = (
  state: TermsAndConditionsState = initialState,
  action: TermsAndConditionsAction
): TermsAndConditionsState => {
  switch (action.type) {
    case SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        terms: action.payload.terms,
        id: action.payload.id,
      };
    case SAVE_SENSITIVE_DATA:
      return {
        ...state,
        idCard: action.payload.idCard,
        mobileNumber: action.payload.mobileNumber,
        referral: action.payload.referral,
      };
    case RESET_SENSITIVE_DATA:
      console.log('rrrr RESET_SENSITIVE_DATA')
      return {
        ...state,
        idCard: '',
        mobileNumber: '',
        referral: '',
      };
    case SET_REGISTER_REFERENCE:
      return {
        ...state,
        registerRef: action.payload.registerRef,
      };
    case SET_OTP_REFERENCE:
      return {
        ...state,
        otpRef: action.payload.otpRef,
        otpExpirationTime: action.payload.otpExpirationTime,
      };
    case RESET_OTP_REFERENCE:
      return {
        ...state,
        otpRef: '',
        otpExpirationTime: '',
      };
    case OTP_MAX_ATTEMPT_COUNT:
      return {
        ...state,
        isMaxAttempOtp: true,
      };
    case RESET_OTP_ATTEMPT_COUNT:
      return {
        ...state,
        isMaxAttempOtp: false,
      };
    case SET_AUTHENTICATION_REFERENCE:
      return {
        ...state,
        authenticationWebUri: action.payload.authenticationWebUri,
        authenticationApplicationUri: action.payload.authenticationApplicationUri,
        expiryDateTime: action.payload.expiryDateTime,
        authenticationCount: action.payload.authenticationCount
      };
    case SET_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: action.payload.bankAccounts,
      };
    case SET_DEFAULT_BANK_ACCOUNTS:
      return {
        ...state,
        defaultBankAccounts: action.payload.bankAccounts,
      };
    case SET_SURVEYS_LIST:
      return {
        ...state,
        survey: action.payload.survey,
      };
    case SET_CONSENT_LIST:
      return {
        ...state,
        consent: action.payload.consent
      }
    case SET_VALIDATE_CAPTCHA:
      return {
        ...state,
        reCaptchaToken: action.payload.reCaptchaToken
    }
    case SET_HASHED_MOBILE_NO:
      return {
        ...state,
        hashedMobileNumber: action.payload.hashedMobileNumber
    }
    case STAMP_START_LIFF_STATEMENT:
      return {
        ...state,
        isStartLiff: false,
      }
    case RESET_STATE:
      console.log('rrrr RESET_STATE')
      console.log(initialState)
      return initialState;
    default:
      return state;
  }
};

const selectRegister = (state: RootState) => state.register;

export const registerSelector = createSelector(
  [selectRegister],
  (register) => ({
    terms: register?.terms ?? '',
    id: register?.id ?? '',
    idCard: register?.idCard ?? '',
    mobileNumber: register?.mobileNumber ?? '',
    referral: register?.referral ?? '',
    otpRef: register?.otpRef ?? '',
    otpExpirationTime: register?.otpExpirationTime ?? '',
    isMaxAttempOtp: register?.isMaxAttempOtp ?? false,
    registerRef: register?.registerRef ?? '',
    mustConfirmRegisterForReplace: register?.mustConfirmRegisterForReplace ?? false,
    authenticationWebUri: register?.authenticationWebUri ?? '',
    authenticationApplicationUri: register?.authenticationApplicationUri ?? '',
    expiryDateTime: register?.expiryDateTime ?? '',
    authenticationCount: register?.authenticationCount ?? 0,
    bankAccounts: register?.bankAccounts ?? [],
    defaultBankAccounts: register?.defaultBankAccounts ?? [],
    surveys: register?.survey ?? {},
    consents: register?.consent?? [],
    reCaptchaToken: register?.reCaptchaToken?? "",
    hashedMobileNumber: register?.hashedMobileNumber?? "",
    isStartLiff: register?.isStartLiff?? "",
  })
);