import { logEvent } from "./analytics";

export const TRACK_EVENT = 'track_event';

// CATEGORY
export const CATEGORY_REGISTER = 'line_krungsri_simple_register';
export const CATEGORY_COMPLETE = 'line_krungsri_simple_complete';
export const CATEGORY_POPUP = 'line_krungsri_simple_pop_up';
export const CATEGORY_ERROR_POPUP = 'line_krungsri_simple_error_pop_up';
export const CATEGORY_OTP = 'line_krungsri_simple_otp';
export const CATEGORY_OTP_POPUP = 'line_krungsri_simple_otp_pop_up';
export const CATEGORY_OTP_ERROR_POPUP = 'line_krungsri_simple_otp_error_pop_up';
export const CATEGORY_ACCOUNT = 'line_krungsri_simple_account';
export const CATEGORY_ACCOUNT_POPUP = 'line_krungsri_simple_account_pop_up';
export const CATEGORY_ACCOUNT_ERROR_POPUP = 'line_krungsri_simple_account_error_pop_up';

// ACTION
export const ACTION_LAND_TO_REGISTER = 'land_to_register_page';
export const ACTION_CLICK_BUTTON = 'click_button';
export const ACTION_FIELD_ERROR = 'field_error';
export const ACTION_PROFILE_LOCK = 'profile_lock';
export const ACTION_REGISTER_ERROR = 'register_error';

// LABEL
export const LABEL_NEXT = 'next_term_condition';
export const LABEL_BACK = 'back';

 export const pushEvent0 = (customData: Record<string, any>) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_REGISTER,
    action: ACTION_LAND_TO_REGISTER,
    label: 'krungsri_simple_service',
    customDimensions: customData,
  });
};

export const pushExitBrowser = (eventCategory: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: eventCategory,
    action: ACTION_CLICK_BUTTON,
    label: 'exit_register',
  });
};

export const pushEvent1 = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_REGISTER,
    action: ACTION_CLICK_BUTTON,
    label: 'register',
  });
};

export const pushEvent2 = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_REGISTER,
    action: ACTION_CLICK_BUTTON,
    label: 'register',
  });
};

export const pushEvent3 = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_REGISTER,
    action: ACTION_CLICK_BUTTON,
    label: LABEL_BACK,
  });
};

export const pushEvent4 = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_REGISTER,
    action: ACTION_CLICK_BUTTON,
    label: 'verified_by_kma',
  });
};

export const pushEvent5 = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_REGISTER,
    action: ACTION_CLICK_BUTTON,
    label: 'not_verified_by_kma',
  });
};

export const pushEvent6 = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_REGISTER,
    action: ACTION_PROFILE_LOCK,
    label: '1_hour',
  });
};

export const pushEvent7 = (eventCategory: string, errorReason: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: eventCategory,
    action: ACTION_CLICK_BUTTON,
    label: 'return_to_line',
    error: errorReason,
  });
};

export const pushEvent8 = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_REGISTER,
    action: ACTION_PROFILE_LOCK,
    label: 'suspended',
  });
};

export const pushEvent10 = () => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'continue_register',
  });
};

export const pushEvent11 = (errorReason: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'cancel_register',
    error: errorReason,
  });
};

export const pushEvent12 = (errorReason: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_ERROR_POPUP,
    action: ACTION_REGISTER_ERROR,
    label: errorReason,
  });
};

export const pushEvent13 = (errorReason: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_ERROR_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'agree',
    error: errorReason,
  });
};

export const pushEvent14 = (errorReason: string) => {
  logEvent({
    event: TRACK_EVENT,
    category: CATEGORY_POPUP,
    action: ACTION_CLICK_BUTTON,
    label: 'verified_with_otp',
    error: errorReason,
  });
};
