import { all, call } from "redux-saga/effects";
import { watcherExchangeLineToken } from "./tokenSaga";
import {
  watcherFetchTermsAndConditions,
  watcherAcceptTermsAndConditions,
  watcherSaveUserInfo,
  watcherCreateDeepLink,
  watcherVerifyDeepLink,
  watcherSubmitRegister,
  watcherGenerateOtp,
  watcherVerifyOtp,
  watcherGetConsentList,
  watcherSaveConsentsList,
  watcherGetBankAccount,
  watcherVerifyCustomer,
  watcherOnlineStatus,
  watcherValidateCaptcha,
} from "./registerSaga";
import {
  watcherAddProduct,
  watcherGetSurveysList,
  watcherSaveurveysList,
  watcherGetUserSurvey,
  watcherDeleteAccount,
} from "./accoutSaga";
import {
  showPopupAlert,
  hidePopupAlert,
  showPopupVerify,
} from "../components/common/AppProvider";
import { errorMessages } from "../utils/errorCode";
import {
  watcherGetAccountDetail,
  watcherGenerateStatement,
} from "./statementSaga";
import {
  watcherCheckPreRequestOtp,
  watcherCheckPreSettingCondition,
  watcherSaveSurveysSetting,
  watcherUnregisterUser,
} from "./settingSaga";
import { pushEvent10, pushEvent11 } from "../utils/dataLayers/consent_layer";
import { logEvent } from "../utils/dataLayers/analytics";
import { pushOkButton, pushOtpErrorPage } from "../utils/dataLayers/otp_layer";
import { pushEvent13, pushEvent4 } from "../utils/dataLayers/register_after_otp_layer";

function handleError(error: any) {
  const MAX_LENGTH = 500;
  const errorString = JSON.stringify(error);

  const truncatedError =
    errorString.length > MAX_LENGTH
      ? errorString.substring(0, MAX_LENGTH) + "..."
      : errorString;

  return truncatedError;
}

const storeOfflineEvent = (event: string) => {
  let offlineEvents = JSON.parse(localStorage.getItem("offlineEvents") || "[]");
  offlineEvents.push({ event, timestamp: new Date().toISOString() });
  localStorage.setItem("offlineEvents", JSON.stringify(offlineEvents));
};

export function* sendOfflineEventsToGA() {
  const offlineEvents = JSON.parse(
    localStorage.getItem("offlineEvents") || "[]"
  );
  if (offlineEvents.length > 0) {
    for (const event of offlineEvents) {
      if (event.event === "system_error_internet") {
        yield call(pushEvent10, event.event);
      } else if (event.event === "system_error_internet_click") {
        yield call(pushEvent11, "system_error_internet");
      }
    }

    localStorage.removeItem("offlineEvents");
  }
}

export function* handleStatusError(code: string, message: string) {
  const flow = sessionStorage.getItem("flow");

  const isOnline: boolean = navigator.onLine;

  if (!isOnline) {
    code = "LKS0102";
    if (message === "manage-consent-form_system_error_internet") {
      storeOfflineEvent("system_error_internet");
    }
  }

  switch (code) {
    case "LKS0205":
      if (flow === "register") {
        pushOtpErrorPage("incorrect_otp");
      } else {
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_service_otp_error_pop_up",
          action: "otp_error",
          label: "incorrect_otp",
        });
      }
      break;
    case "LKS0605":
      logEvent({
        event: "track_event",
        category: "line_krungsri_simple_service_otp_error_pop_up",
        action: "otp_error",
        label: "customer_info_not_found",
      });
      break;
    case "LKS0503":
      if (flow === "register") {
        pushEvent4("account_no_not_found");
      } else {
        logEvent({
          event: "track_event",
          category: "line_krungsri_simple_manage_account_error_pop_up",
          action: "add_more_account_error",
          label: "account_no_not_found",
        });
      }
      break;
    case "LKS0506":
        if (flow === "register") {
          pushEvent4("account_suspended");
        } else {
          logEvent({
            event: "track_event",
            category: "line_krungsri_simple_manage_account_error_pop_up",
            action: "add_more_account_error",
            label: "account_suspended",
          });
        }
        break;
  }

  const { title, description, iconType } =
    errorMessages[code] ?? errorMessages["LKS0101"];

  yield call(showPopupAlert, {
    logMessage: `code: ${code}, message: ${message}`,
    title: title,
    description: description,
    iconType: iconType,
    ButtonText: "ตกลง",
    ButtonClick: () => {
      if (message === "manage-consent-form_system_error_internet") {
        storeOfflineEvent("system_error_internet_click");
      }
      hidePopupAlert();
      switch (code) {
        case "LKS0605":
          logEvent({
            event: "track_event",
            category: "line_krungsri_simple_service_otp_error_pop_up",
            action: "click_button",
            label: "agree",
            error: "customer_info_not_found",
          });
          break;
        case "LKS0402":
          logEvent({
            event: "track_event",
            category: "line_krungsri_simple_error_pop_up",
            action: "click_button",
            label: "agree",
            error: "customer_info_not_found",
          });
          break;
        case "LKS0503":
          if (flow === "register") {
            pushEvent13("account_no_not_found");
          } else {
            logEvent({
              event: "track_event",
              category: "line_krungsri_simple_account_error_pop_up",
              action: "click_button",
              label: "agree",
              error: "account_no_not_found",
            });
          }
          break;
        case "LKS0506":
          if (flow === "register") {
            pushEvent13("account_suspended");
          } else {
            logEvent({
              event: "track_event",
              category: "line_krungsri_simple_account_error_pop_up",
              action: "click_button",
              label: "agree",
              error: "account_suspended",
            });
          }
          break;
        case "LKS0205":
          if (flow === "register") {
            pushOkButton("incorrect_otp");
          } else {
            logEvent({
              event: "track_event",
              category: "line_krungsri_simple_service_otp_error_pop_up",
              action: "click_button",
              label: "agree",
              error: "incorrect_otp",
            });
          }
          break;
        default:
          break;
      }
    },
  });
  // yield call(showPopupAlert, {
  //   title: `ERROR CODE: ${code}`,
  //   description: `MESSAGE: ${message}`,
  //   iconType: 1,
  //   ButtonText: 'OK',
  //   ButtonClick: () => {
  //     hidePopupAlert();
  //   },
  // });
}

export default function* rootSaga() {
  yield all([
    watcherExchangeLineToken(),
    watcherFetchTermsAndConditions(),
    watcherAcceptTermsAndConditions(),
    watcherSaveUserInfo(),
    watcherCreateDeepLink(),
    watcherVerifyDeepLink(),
    watcherSubmitRegister(),
    watcherAddProduct(),
    watcherGetSurveysList(),
    watcherSaveurveysList(),
    watcherGenerateOtp(),
    watcherVerifyOtp(),
    watcherGetAccountDetail(),
    watcherGenerateStatement(),
    watcherGetUserSurvey(),
    watcherSaveSurveysSetting(),
    watcherCheckPreSettingCondition(),
    watcherGetConsentList(),
    watcherSaveConsentsList(),
    watcherUnregisterUser(),
    watcherGetBankAccount(),
    watcherDeleteAccount(),
    watcherCheckPreRequestOtp(),
    watcherVerifyCustomer(),
    watcherOnlineStatus(),
    watcherValidateCaptcha(),
  ]);
}
