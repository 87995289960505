import React, { CSSProperties, useEffect, useState } from "react";
import PageProvider from "../../../components/layout/PageProvider";
import ButtomSpan from "../../../components/layout/BottomSpan";
import { Description, Title } from "../../../components/common/Texts";
import ConsentForm from "../../../components/common/ConsentForm";
import { useDispatch, useSelector } from "react-redux";
import { saveConsentList } from "../../../actions/registerAction";
import { RootState } from "../../../store/store";
import { registerSelector } from "../../../reducers/registerReducer";
import Button from "../../../components/common/Button";
import { safeNavigate } from "../../../utils/navigation";
import Colors from "../../../assets/styles/Colors";
import { Fonts } from "../../../assets/styles/Fonts";
import { logEvent } from "../../../utils/dataLayers/analytics";

const BindingConsentForm: React.FC = () => {
  const dispatch = useDispatch();
  const { consents } = useSelector((state: RootState) =>
    registerSelector(state)
  );
  // const consents =
  //     [
  //         {
  //             "id": '1',
  //             "contentHtml": "<p>ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณให้แก่ บริษัทในกลุ่มกรุงศรี เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ </p>",
  //             "contentText": "ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณให้แก่ บริษัทในกลุ่มกรุงศรี เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ ",
  //             "consentRemark": "พันธมิตรของกลุ่มกรุงศรีรวมถึงบุคคลภายนอกที่เกี่ยวข้อง ได้แก่ ประเภทกิจการด้านการประกันภัย 1) บจก. เอไอจี ประกันภัย (ประเทศไทย) 2) บจก. เอไอเอ (ประเทศไทย) ประเภทกิจการด้านการเงินอื่นๆ  1) บจก. จีฟิน เซอร์วิสเซส (ที)",
  //             "action": "Y",
  //             "entity": {
  //                 "code": "1100",
  //                 "nameTh": "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
  //                 "nameEn": "Bank of Ayudhya Public Company Limited."
  //             },
  //             "channel": {
  //                 "code": "SIMPLE-CLICK",
  //                 "nameTh": "สาขา",
  //                 "nameEn": "Branch"
  //             },
  //             "signedVersion": "1.01.01",
  //             "updatedAt": "2020-12-27T23:00:00+07:00",
  //             "consentReference": "134134"
  //         },
  //         {
  //             "id": '2',
  //             "contentHtml": "<p>ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณ ให้แก่พันธมิตร ของกลุ่มกรุงศรี และบุคคลภายนอกที่เกี่ยวข้อง รวมทั้งยินยอมให้ผู้รับข้อมูลดังกล่าว เก็บรวบรวม และใช้ข้อมูลของคุณ เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ</p>",
  //             "contentText": "ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณ ให้แก่พันธมิตร ของกลุ่มกรุงศรี และบุคคลภายนอกที่เกี่ยวข้อง รวมทั้งยินยอมให้ผู้รับข้อมูลดังกล่าว เก็บรวบรวม และใช้ข้อมูลของคุณ เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ",
  //             "consentRemark": "พันธมิตรของกลุ่มกรุงศรีรวมถึงบุคคลภายนอกที่เกี่ยวข้อง ได้แก่ ประเภทกิจการด้านการประกันภัย 1) บจก. เอไอจี ประกันภัย (ประเทศไทย) 2) บจก. เอไอเอ (ประเทศไทย) ประเภทกิจการด้านการเงินอื่นๆ  1) บจก. จีฟิน เซอร์วิสเซส (ที)",
  //             "action": "N",
  //             "entity": {
  //                 "code": "1100",
  //                 "nameTh": "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
  //                 "nameEn": "Bank of Ayudhya Public Company Limited."
  //             },
  //             "channel": {
  //                 "code": "SIMPLE-CLICK",
  //                 "nameTh": "สาขา",
  //                 "nameEn": "Branch"
  //             },
  //             "signedVersion": "2.01.01",
  //             "updatedAt": "2020-12-27T08:00:00+07:00",
  //             "consentReference": "1234143134"
  //         },
  // {
  //     "id": '3',
  //     "contentHtml": "<p>ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณ ให้แก่พันธมิตร ของกลุ่มกรุงศรี และบุคคลภายนอกที่เกี่ยวข้อง รวมทั้งยินยอมให้ผู้รับข้อมูลดังกล่าว เก็บรวบรวม และใช้ข้อมูลของคุณ เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ</p>",
  //     "contentText": "ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณ ให้แก่พันธมิตร ของกลุ่มกรุงศรี และบุคคลภายนอกที่เกี่ยวข้อง รวมทั้งยินยอมให้ผู้รับข้อมูลดังกล่าว เก็บรวบรวม และใช้ข้อมูลของคุณ เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ",
  //     "consentRemark": "พันธมิตรของกลุ่มกรุงศรีรวมถึงบุคคลภายนอกที่เกี่ยวข้อง ได้แก่ ประเภทกิจการด้านการประกันภัย 1) บจก. เอไอจี ประกันภัย (ประเทศไทย) 2) บจก. เอไอเอ (ประเทศไทย) ประเภทกิจการด้านการเงินอื่นๆ  1) บจก. จีฟิน เซอร์วิสเซส (ที)",
  //     "action": "W",
  //     "entity": {
  //         "code": "1100",
  //         "nameTh": "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
  //         "nameEn": "Bank of Ayudhya Public Company Limited."
  //     },
  //     "channel": {
  //         "code": "SIMPLE-CLICK",
  //         "nameTh": "สาขา",
  //         "nameEn": "Branch"
  //     },
  //     "signedVersion": "1.01.01",
  //     "updatedAt": "2020-12-27T23:00:00+07:00",
  //     "consentReference": "123412342"
  // },
  // {
  //     "id": '4',
  //     "contentHtml": "<p>ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณ ให้แก่พันธมิตร ของกลุ่มกรุงศรี และบุคคลภายนอกที่เกี่ยวข้อง รวมทั้งยินยอมให้ผู้รับข้อมูลดังกล่าว เก็บรวบรวม และใช้ข้อมูลของคุณ เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ</p>",
  //     "contentText": "ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณ ให้แก่พันธมิตร ของกลุ่มกรุงศรี และบุคคลภายนอกที่เกี่ยวข้อง รวมทั้งยินยอมให้ผู้รับข้อมูลดังกล่าว เก็บรวบรวม และใช้ข้อมูลของคุณ เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ",
  //     "consentRemark": "พันธมิตรของกลุ่มกรุงศรีรวมถึงบุคคลภายนอกที่เกี่ยวข้อง ได้แก่ ประเภทกิจการด้านการประกันภัย 1) บจก. เอไอจี ประกันภัย (ประเทศไทย) 2) บจก. เอไอเอ (ประเทศไทย) ประเภทกิจการด้านการเงินอื่นๆ  1) บจก. จีฟิน เซอร์วิสเซส (ที)",
  //     "action": "P",
  //     "entity": {
  //         "code": "1100",
  //         "nameTh": "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
  //         "nameEn": "Bank of Ayudhya Public Company Limited."
  //     },
  //     "channel": {
  //         "code": "SIMPLE-CLICK",
  //         "nameTh": "สาขา",
  //         "nameEn": "Branch"
  //     },
  //     "signedVersion": "2.01.01",
  //     "updatedAt": "2020-12-27T08:00:00+07:00",
  //     "consentReference": "1341234"
  // },
  // {
  //     "id": '5',
  //     "contentHtml": "<p>ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณ ให้แก่พันธมิตร ของกลุ่มกรุงศรี และบุคคลภายนอกที่เกี่ยวข้อง รวมทั้งยินยอมให้ผู้รับข้อมูลดังกล่าว เก็บรวบรวม และใช้ข้อมูลของคุณ เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ</p>",
  //     "contentText": "ยินยอมให้ กลุ่มกรุงศรี เก็บรวบรวมข้อมูลของคุณไม่ว่ากลุ่มกรุงศรีจะได้รับจากคุณโดยตรงหรือจากแหล่งอื่น ใช้ และเปิดเผยข้อมูลของคุณ ให้แก่พันธมิตร ของกลุ่มกรุงศรี และบุคคลภายนอกที่เกี่ยวข้อง รวมทั้งยินยอมให้ผู้รับข้อมูลดังกล่าว เก็บรวบรวม และใช้ข้อมูลของคุณ เพื่อการวิเคราะห์ การส่งเสริมการขาย หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการ รวมถึงสิทธิพิเศษที่เป็นประโยชน์และตรงใจคุณ",
  //     "consentRemark": "พันธมิตรของกลุ่มกรุงศรีรวมถึงบุคคลภายนอกที่เกี่ยวข้อง ได้แก่ ประเภทกิจการด้านการประกันภัย 1) บจก. เอไอจี ประกันภัย (ประเทศไทย) 2) บจก. เอไอเอ (ประเทศไทย) ประเภทกิจการด้านการเงินอื่นๆ  1) บจก. จีฟิน เซอร์วิสเซส (ที)",
  //     "action": "I",
  //     "entity": {
  //         "code": "1100",
  //         "nameTh": "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
  //         "nameEn": "Bank of Ayudhya Public Company Limited."
  //     },
  //     "channel": {
  //         "code": "SIMPLE-CLICK",
  //         "nameTh": "สาขา",
  //         "nameEn": "Branch"
  //     },
  //     "signedVersion": "2.01.01",
  //     "updatedAt": "2020-12-27T08:00:00+07:00",
  //     "consentReference": "12341243"
  // }
  // ]
  const [consentList, setConsentList] = useState(consents);

  const handleConsentChange = (index: number, action: string) => {
    const updatedConsentList = consentList.map((item, i) =>
      i === index ? { ...item, action } : item
    );
    setConsentList(updatedConsentList);
  };

  const handleSubmit = () => {
    const transformedConsents = consentList.map((consent) => ({
      id: Number(consent.id),
      action: consent.action,
    }));
    dispatch(saveConsentList(transformedConsents));

    const customDimensions: { [key: string]: any } = {};

    consentList.forEach((consent) => {
      const consentId = Number(consent.id);
      const consentVariable =
        consentId % 2 === 0
          ? "krungsri_group_consent"
          : "krungsri_alli_consent";

      let consentValue: string;

      if (consent.action === "N") {
        consentValue = "ignored";
      } else if (consent.action === "Y") {
        consentValue = "granted";
      } else {
        consentValue = "denied";
      }

      customDimensions[`${consentVariable}`] = consentValue;
    });

    logEvent({
      event: "track_event",
      category: "line_krungsri_simple_account",
      action: "click_button",
      label: "confirm_consent",
      customDimensions: customDimensions,
    });
  };

  useEffect(() => {
    setConsentList(consents);
  }, [consents]);
  const gotoPreviousPage = () => {
    handleSubmit();
    safeNavigate("/binding-consent");
  };

  const goToNextPage = () => {
    handleSubmit();
    safeNavigate("/survey");
  };

  return (
    <PageProvider>
      <img
        onClick={gotoPreviousPage}
        src="/svg/back.svg"
        style={{
          height: 24,
          width: 24,
          cursor: "pointer",
          marginBottom: "24px",
        }}
      />
      <div style={{ marginBottom: "24px" }}>
        <Title
          style={{
            marginBottom: "8px",
            fontSize: "20px",
            lineHeight: "30px",
            color: Colors.TEXT_BODY_PRIMARY,
          }}
        >
          การให้ความยินยอมเพื่อวัตถุประสงค์ทางการตลาด
        </Title>
        <Title
          style={{
            fontSize: "14px",
            lineHeight: "24px",
            color: Colors.TEXT_BODY_PRIMARY,
          }}
        >
          การให้ความยินยอมนี้ ไม่มีผลกระทบต่อการพิจารณา
          อนุมัติคำขอสมัครใช้ผลิตภัณฑ์และบริการของคุณ
        </Title>
      </div>

      <div>
        {consentList.map((item, index) => (
          <div key={index}>
            <ConsentForm
              id={item.id}
              onChange={handleConsentChange}
              index={index}
              remark={item.remark}
              question={item.name}
            />
          </div>
        ))}
      </div>

      <div style={{ marginTop: '24px', padding: '16px', backgroundColor: "#FFFBE6", borderRadius: '8px' }}>
        <Description
          style={{
            fontFamily: Fonts.Krungsri_Light,
            fontSize: "14px",
            lineHeight: "22px",
            color: Colors.TEXT_BODY_PRIMARY,
          }}
        >
          คุณได้อ่านและรับทราบรายละเอียดต่างๆเกี่ยวกับการ
          ขอความยินยอมและเข้าใจว่าสามารถใช้สิทธิถอน ความยินยอมเมื่อใดก็ได้
          ทั้งนี้ คุณสามารถศึกษา รายละเอียดเกี่ยวกับการเก็บรวบรวม ใช้
          หรือเปิดเผย ข้อมูลส่วนบุคคลของกลุ่มกรุงศรีและสิทธิต่างๆของคุณ ได้
          ซึ่งรวมถึงการขอถอนความยินยอมผ่านช่องทางสาขา ช่องทางอิเล็กทรอนิกส์
          และ/หรือ ช่องทางอื่นๆ ตามที่
          ระบุไว้ในประกาศการคุ้มครองข้อมูลส่วนบุคคลของกลุ่ม กรุงศรีที่
          <a
            href="https://www.krungsri.com/pdpa/privacy-notice-th"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#F76F00",
              textDecoration: "none",
            }}
          >
            {" www.krungsri.com/pdpa/privacy-notice-th"}
          </a>
        </Description>
      </div>

      <ButtomSpan>
        <Button
          text={"ยืนยัน"}
          onClick={goToNextPage}
          type={0}
          style={{ margin: "24px 0 16px 0" }}
        />
      </ButtomSpan>
    </PageProvider>
  );
};

export default BindingConsentForm;
