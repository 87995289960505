import { useEffect, useState, useRef, ChangeEvent } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title, Description } from '../../../components/common/Texts';
import { useLocation, useNavigate } from 'react-router-dom';
import PopupAlert from '../../../components/common/popup/PopUpAlert';
import Spinner from '../../../components/common/Spinner';
import Colors from '../../../assets/styles/Colors';
import PopupValid from '../../../components/common/popup/PopupValid';
import ScreenAlert from '../../../components/common/ScreenAlert';
import { useSelector, useDispatch } from 'react-redux';
import { registerSelector } from '../../../reducers/registerReducer';
import { RootState } from '../../../reducers/rootReducer';
import { generateOtp, verifyOtp } from '../../../actions/registerAction';
import { safeNavigate } from '../../../utils/navigation';
import {
    pushClickButtonLayer,
    pushOkButton,
    CATEGORY_OTP,
    pushOtpErrorPage,
} from '../../../utils/dataLayers/otp_layer';
import { errorMessages } from '../../../utils/errorCode';
import { pushEvent7 } from '../../../utils/dataLayers/before_register_layer';
import { logEvent } from '../../../utils/dataLayers/analytics';

export default function VerifyOTPPage() {
    const dispatch = useDispatch()
    const location = useLocation();
    const { mobileNumber, otpRef, registerRef } = useSelector((state: RootState) => registerSelector(state));
    const params = new URLSearchParams(location.search);
    const headerParams = params.get("header")
    const iconSourcesParams = params.get("iconSources")
    const titleParams = params.get("title")
    const typeParams = params.get("type")
    const descParams = params.get("desc")
    const buttonTextParams = params.get("buttonText")
    const header = headerParams ? headerParams : '';
    const iconSources = iconSourcesParams ? iconSourcesParams : '';
    const title = titleParams ? titleParams : '';
    const type = typeParams ? Number(typeParams) : 1;
    const desc = descParams ? descParams : '';
    const buttonText = buttonTextParams ? buttonTextParams : '';

    const timerStartTimestamp = JSON.parse(sessionStorage.getItem('timerStartTimestamp') || (Date.now().toString()));
    const initialTimeLeft = 300; //mock 300 seconds
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
    const [actualOtp, setActualOtp] = useState<string[]>(new Array(6).fill(""));
    const [oldOtpIdx, setOldOtpIdx] = useState<number>(-1);
    const [isOtpValid, setIsOtpValid] = useState<boolean>(false);
    const [attemptCount, setAttemptCount] = useState<number>(0);
    const [otpCount, setOtpCount] = useState<number>(0);
    const [isMaxOtpAttempts, setisMaxOtpAttempts] = useState<boolean>(false);
    const [isProceedDisabled, setIsProceedDisabled] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(initialTimeLeft - Math.floor((Date.now() - timerStartTimestamp) / 1000));
    const [isTimerExpired, setIsTimerExpired] = useState<boolean>(false);
    const [iconType, setIconType] = useState<number>(1);
    const [popUpTitle, setPopupTitle] = useState<string>("");
    const [popUpDescription, setPopupDescription] = useState<string>("");
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showValidPopup, setShowValidPopup] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const timerRef = useRef<number | undefined>();
    const requestStatementByOTP = JSON.parse(sessionStorage.getItem('requestStatementByOTP') || 'false');

    const navigate = useNavigate();
    const flow = sessionStorage.getItem('flow');

    useEffect(() => {
        if (showPopup && popUpTitle) {
            if (flow !== 'register') {
                switch (popUpTitle) {
                    case errorMessages['LKS0202'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_service_otp_error_pop_up',
                            action: 'otp_error',
                            label: 'otp_suspended',
                        })
                        break;
                    case errorMessages['LKS0203'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_service_otp_error_pop_up',
                            action: 'otp_error',
                            label: 'otp_expired',
                        })
                        break;
                    case errorMessages['LKS0204'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_service_otp_error_pop_up',
                            action: 'otp_error',
                            label: 'incorrect_otp_over_limit',
                        })
                        break;
                    case errorMessages['LKS0205'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_service_otp_error_pop_up',
                            action: 'otp_error',
                            label: 'incorrect_otp',
                        })
                        break;
                    default:
                        break;
                }
            } else {
                switch (popUpTitle) {
                    case errorMessages['LKS0202'].title:
                        pushOtpErrorPage('otp_suspended');
                        break;
                    case errorMessages['LKS0203'].title:
                        pushOtpErrorPage('otp_expired');
                        break;
                    case errorMessages['LKS0204'].title:
                        pushOtpErrorPage('incorrect_otp_over_limit');
                        break;
                    case errorMessages['LKS0205'].title:
                        pushOtpErrorPage('incorrect_otp');
                        break;
                    default:
                        break;
                }
            }
        }
    }, [showPopup, popUpTitle]);

    const handleOTPChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        if (!isNaN(Number(e.target.value))) {
            let newOtp = [...otp];
            let newActualOtp = [...actualOtp];

            newActualOtp[index] = e.target.value;
            newOtp[index] = e.target.value;

            setOtp(newOtp);
            setActualOtp(newActualOtp);

            const allFilled = newActualOtp.every((digit) => digit !== "");

            setTimeout(() => {
                if (allFilled || (actualOtp[index] === "")) {
                    setOtp((prevOtp) => {
                        const updatedOtp = [...prevOtp];
                        if (updatedOtp[index] !== "") {
                            updatedOtp[index] = "•";
                        }
                        if (updatedOtp[oldOtpIdx] !== "") {
                            updatedOtp[oldOtpIdx] = "•";
                        }
                        return updatedOtp;
                    });
                }
            }, 500);

            if (index < 5 && e.target.value) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    // const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();

    useEffect(() => {
        sessionStorage.setItem('timerStartTimestamp', (Date.now() - (initialTimeLeft - timeLeft) * 1000).toString());
    }, [timeLeft]);

    const startTimer = () => {
        if (timerRef.current) clearInterval(timerRef.current);

        timerRef.current = window.setInterval(() => {
            setTimeLeft((prev) => {
                const newTimeLeft = initialTimeLeft - Math.floor((Date.now() - timerStartTimestamp) / 1000);

                if (newTimeLeft <= 0) {
                    clearInterval(timerRef.current);
                    setIsTimerExpired(true);
                    setIconType(1);
                    if (otpCount + 1 >= 5) {
                        setisMaxOtpAttempts(true);
                    } else if (!isMaxOtpAttempts) {
                        setPopupTitle("รหัส OTP หมดอายุ");
                        setPopupDescription("กรุณาขอรหัส OTP ใหม่");
                        setShowPopup(true);
                        setTimeout(() => {
                            setShowPopup(false);
                        }, 3000);
                    }
                    setTimeLeft(initialTimeLeft);
                    sessionStorage.setItem('timerStartTimestamp', Date.now().toString());
                    return initialTimeLeft;
                }
                return newTimeLeft;
            });
        }, 1000);
    };

    useEffect(() => {
        startTimer();
        return () => {
            if (timerRef.current) clearInterval(timerRef.current);
        };
    }, []);

    const handleResendOTP = () => {
        if (requestStatementByOTP) {
            dispatch(generateOtp({ mobileNumber: mobileNumber, isResend: true }))
        } else {
            sessionStorage.setItem('flow', 'register');
            dispatch(generateOtp({ mobileNumber: '', isResend: true }))
        }
        setOtpCount((prevCnt) => prevCnt + 1)
        setIconType(0)
        setPopupTitle("ส่งรหัส OTP ไปยังเบอร์มือถือแล้ว")
        setShowPopup(true);
        setShowValidPopup(true);
        setTimeout(() => {
            setShowPopup(false);
            setShowValidPopup(false);
        }, 3000)
        setOtp(new Array(6).fill(""));
        setActualOtp(new Array(6).fill(""));
        setOldOtpIdx(-1);
        setTimeLeft(initialTimeLeft);
        setIsTimerExpired(false);
        setAttemptCount(0);
        setIsProceedDisabled(false);
        sessionStorage.setItem('timerStartTimestamp', Date.now().toString());
        startTimer();
        pushClickButtonLayer('request_otp');
    };

    const onClosePopup = () => {
        setShowPopup(false);
        if (flow !== 'register') {
            switch (popUpTitle) {
                case errorMessages['LKS0202'].title:
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_service_otp_error_pop_up',
                        action: 'click_button',
                        label: 'agree',
                        error: 'otp_suspended'
                    })
                    break;
                case errorMessages['LKS0203'].title:
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_service_otp_error_pop_up',
                        action: 'click_button',
                        label: 'agree',
                        error: 'otp_expired'
                    })
                    break;
                case errorMessages['LKS0204'].title:
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_service_otp_error_pop_up',
                        action: 'click_button',
                        label: 'agree',
                        error: 'incorrect_otp_over_limit'
                    })
                    break;
                case errorMessages['LKS0205'].title:
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_service_otp_error_pop_up',
                        action: 'click_button',
                        label: 'agree',
                        error: 'incorrect_otp'
                    })
                    break;
                default:
                    break;
            }
        } else {
            switch (popUpTitle) {
                case errorMessages['LKS0202'].title:
                    pushEvent7(CATEGORY_OTP, errorMessages['LKS0202'].title);
                    break;
                case errorMessages['LKS0203'].title:
                    pushOkButton('otp_expired');
                    break;
                case errorMessages['LKS0204'].title:
                    pushOkButton('incorrect_otp_over_limit');
                    break;
                case errorMessages['LKS0205'].title:
                    pushOkButton('incorrect_otp');
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        const allFilled = otp.every((digit) => digit !== "");
        setIsOtpValid(allFilled);
    }, [otp]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const validateOTP = (otp: string) => {
        const mockOTP = "111111";
        setIconType(1);
        setShowSpinner(true);

        setTimeout(() => {
            setShowSpinner(false);
        }, 300);
        // if (otp !== mockOTP) {
        //     setAttemptCount((prevCount) => prevCount + 1);
        //     if (attemptCount + 1 === 3) {
        //         setIsProceedDisabled(true);
        //         setPopupTitle("กรอก OTP ไม่ถูกต้องเกินกำหนด");
        //         setPopupDescription("กรุณาขอรหัส OTP ใหม่ และลองกรอกอีกครั้ง");
        //     } else {
        //         setPopupTitle("รหัส OTP ไม่ถูกต้อง");
        //         setPopupDescription("กรุณากรอกรหัส OTP อีกครั้ง หรือขอรหัสใหม่");
        //     }

        //     setTimeout(() => {
        //         setShowPopup(true);
        //     }, 300);
        //     return false;
        // }

        setIconType(0);
        setPopupTitle("ยืนยันด้วย OTP สำเร็จ");
        setPopupDescription("");
        setTimeout(() => {
            setShowPopup(true);
        }, 400);
        return true
    };

    const handleBackspace = (index: number) => {
        const newOtp = [...otp];
        const newActualOtp = [...actualOtp];
        newOtp[index] = '';
        newActualOtp[index] = '';
        setOtp(newOtp);
        setActualOtp(newActualOtp);
        if (index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const goToNextPage = () => {
        let otpCode = actualOtp.join("");
        if (requestStatementByOTP) {
            dispatch(verifyOtp({ otpRef, otpCode }))
        } else {
            dispatch(verifyOtp({ otpRef, otpCode }));
        }
        pushClickButtonLayer('confirm_otp');
    };

    const goToPreviousPage = () => {
        pushClickButtonLayer('back');
        const flow = sessionStorage.getItem('flow');
        if (flow === 'register') {
            safeNavigate('/info');

        } else {
            safeNavigate('/receive-otp');
        }
    }

    return (
        <PageProvider>
            {
                isMaxOtpAttempts ? (
                    ""
                ) : (
                    <>
                        <Title>ยืนยันด้วย OTP</Title>
                        <div style={{ marginTop: 24 }} />
                        <div style={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px'
                            }}>
                                <Description>
                                    <span style={{
                                        fontWeight: 700,
                                    }}>
                                        กรุณากรอกรหัส OTP ที่ส่งไปยัง <br />
                                        {`XXX-XXX-${mobileNumber.slice(6, 10)}`}
                                    </span>
                                </Description>
                                <Description>
                                    <span>
                                        รหัสจะหมดอายุใน 5 นาที <br />
                                        อ้างอิง: {otpRef}
                                    </span>
                                </Description>
                            </div>
                            <div style={{
                                display: 'flex',
                                gap: '8px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                {otp.map((data, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength={1}
                                        value={data}
                                        ref={(el) => inputRefs.current[index] = el}
                                        onChange={e => handleOTPChange(e, index)}
                                        style={{
                                            width: '48px',
                                            height: '48px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            borderRadius: '8px',
                                            border: '1px solid #D7D7D7',
                                            backgroundColor: '#FFF',
                                            fontSize: '18px',
                                            lineHeight: '48px',
                                            transition: 'border-color 0.2s',
                                            outline: 'none',
                                            boxSizing: 'border-box',
                                        }}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        disabled={isTimerExpired}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Backspace' && !otp[index]) handleBackspace(index);
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.borderColor = Colors.PRIMARY_KS_YELLOW;
                                            e.target.style.borderWidth = '1.5px';
                                        }}
                                        onBlur={(e) => {
                                            e.target.style.borderColor = '#ccc';
                                            e.target.style.borderWidth = '1px';
                                        }}
                                    />
                                ))}
                            </div>
                            <Description>
                                {isTimerExpired ? (
                                    <button style={{
                                        display: 'inline-flex',
                                        padding: '4px 8px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        borderRadius: '4px',
                                        border: `1px solid ${Colors.ORANGE}`,
                                        background: `${Colors.WHITE}`,
                                        color: `${Colors.ORANGE}`
                                    }}
                                        onClick={handleResendOTP}>
                                        <img src={'/svg/retry.svg'} alt="resend otp" style={{
                                            width: '16px',
                                            height: '16px',
                                        }} />
                                        <span>
                                            ขอรหัส OTP ใหม่
                                        </span>
                                    </button>
                                ) : (
                                    <span>
                                        ขอรหัส OTP ใหม่ได้ในอีก <span style={{ fontWeight: 700 }}>
                                            {formatTime(timeLeft)}
                                        </span>
                                    </span>
                                )}
                            </Description>
                        </div>
                    </>
                )
            }
            {
                isMaxOtpAttempts && isTimerExpired ? (
                    <ScreenAlert header={header} iconSources={iconSources} title={title}
                        desc={desc} buttonText={buttonText} type={type}
                        countdownDuration={3600} onCountdownEnd={'/info'} />
                ) : ""
            }
            {
                showPopup && (
                    <div style={{ width: '375px`' }}>
                        {
                            (
                                showValidPopup ? (
                                    <PopupValid
                                        title={popUpTitle}
                                        
                                    />
                                ) : (
                                    <PopupAlert
                                        title={popUpTitle}
                                        iconType={iconType}
                                        description={popUpDescription}
                                        ButtonClick={onClosePopup}
                                    />
                                )
                            )
                        }
                    </div>
                )
            }

            <ButtomSpan>
                {isMaxOtpAttempts ? (
                    ""
                ) : (
                    <>
                        <Button text={'ยืนยัน'} type={isOtpValid && !isProceedDisabled ? 0 : 1} onClick={goToNextPage} />
                        <div style={{ marginBottom: 24 }} />
                        <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
                    </>
                )}
            </ButtomSpan>

            {
                showSpinner && <Spinner />
            }

        </PageProvider >
    );
};
