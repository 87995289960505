export const config = {
    "REACT_APP_ENVIRONMENT": "${REACT_APP_ENVIRONMENT}",
    "REACT_APP_LIFF_ID": "${REACT_APP_LIFF_ID}",
    "REACT_APP_API_DOMAIN": "${REACT_APP_API_DOMAIN}",
    "REACT_APP_RECAPTCHA_KEY": "${REACT_APP_RECAPTCHA_KEY}",
    "REACT_APP_GTM_ID": "${REACT_APP_GTM_ID}",
}

export function initConfig() {
    for (const key in config) {
        const value = config[key]

        // Use value from process.env if value is not set
        if (value == '${'+key+'}') {
            config[key] = process.env[key]
        }
    }
}