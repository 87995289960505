import React, { useState, ChangeEvent, useEffect } from "react";
import PageProvider from '../../../components/layout/PageProvider';
import Button from '../../../components/common/Button';
import ButtomSpan from "../../../components/layout/BottomSpan"
import { Title, Description } from '../../../components/common/Texts';
import { useNavigate } from 'react-router-dom';
import PopupAlert from "../../../components/common/popup/PopUpAlert";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from '../../../actions/accountAction';
import { registerSelector } from "../../../reducers/registerReducer";
import { RootState } from "../../../reducers/rootReducer";
import CustomInput from "../../../components/common/CustomInput";
import { formatAccountNumber } from "../../../utils/formatInput";
import { errorMessages } from "../../../utils/errorCode";
import { showPopupAlert, hidePopupAlert } from "../../../components/common/AppProvider";
import { safeNavigate } from "../../../utils/navigation";
import { pushErrorFieldAppearReg, pushEvent10, pushEvent12, pushEvent13, pushEvent2, pushEvent4 } from "../../../utils/dataLayers/register_after_otp_layer";
import { logEvent } from "../../../utils/dataLayers/analytics";
import { getOSByPlatform } from "../../../utils/getOSByPlatform";

export default function AddProductPage() {
    const dispatch = useDispatch()
    const { bankAccounts, defaultBankAccounts } = useSelector((state: RootState) => registerSelector(state));

    const [accountID, setAccountID] = useState<string>("");
    const [isAccountIDValid, setIsAccountIDValid] = useState(false);
    const [iconType, setIconType] = useState<number>(1);
    const [popUpTitle, setPopupTitle] = useState<string>("");
    const [popUpDescription, setPopupDescription] = useState<string>("");
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    const flow = sessionStorage.getItem('flow');
    const [isKeyboard, setIsKeyboard] = useState<boolean>(false);

    const adjustHeight = () => {
        const height = window.innerHeight;
        if (height < 600) {
            setIsKeyboard(true)
        } else {
            setIsKeyboard(false)
        }
    };

    useEffect(() => {
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, []);

    const handleAccountID = (e: ChangeEvent<HTMLInputElement>) => {
        setAccountID(e.target.value)
    };

    const goToNextPage = () => {
        if (flow === 'confirm-account') {
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_manage_account',
                action: 'click_button',
                label: 'add_account'
            });
        } else {
            pushEvent10();
        }
        const accountExists = bankAccounts.some(
            (account) => account.accountNumber === accountID.replace(/-/g, '')
        );
        if (accountExists) {
            setPopupTitle("มีบัญชีนี้ในระบบแล้ว")
            setPopupDescription("กรุณากรอกเลขที่บัญชีอื่น")
            setShowPopup(true)
        } else {
            dispatch(addProduct(accountID.replace(/-/g, ''), bankAccounts, false, defaultBankAccounts));
        }
    };

    useEffect(() => {
        if (showPopup && popUpTitle) {
            if (flow == 'confirm-account') {
                switch (popUpTitle) {
                    case errorMessages['LKS0503'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account_error_pop_up',
                            action: 'add_more_account_error',
                            label: 'account_not_found'
                        })
                        break;
                    case errorMessages['LKS0505'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account_error_pop_up',
                            action: 'add_more_account_error',
                            label: 'account_already_exists'
                        })
                        break;
                    case errorMessages['LKS0506'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account_error_pop_up',
                            action: 'add_more_account_error',
                            label: 'account_suspended'
                        })
                        break;
                    default:
                        break;
                }
            }
            else {
                switch (popUpTitle) {
                    case errorMessages['LKS0503'].title:
                        pushEvent12('account_no_not_found');
                        break;
                    case errorMessages['LKS0505'].title:
                        pushEvent12('account_already_exists');
                        break;
                    case errorMessages['LKS0506'].title:
                        pushEvent12('account_suspended');
                        break;
                    default:
                        break;
                }
            }
        }
    }, [showPopup, popUpTitle]);

    const goToPreviousPage = () => {
        safeNavigate('/confirm-account');
        if (flow === 'confirm-account') {
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_manage_account',
                action: 'click_button',
                label: 'back'
            });
        } else {
            pushEvent2();
        }
    };

    const onClosePopup = () => {
        setShowPopup(false);
        if (popUpTitle) {
            if (flow == 'confirm-account') {
                switch (popUpTitle) {
                    case errorMessages['LKS0503'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account_error_pop_up',
                            action: 'click_button',
                            label: 'agree',
                            error: 'account_not_found'
                        })
                        break;
                    case errorMessages['LKS0505'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account_error_pop_up',
                            action: 'click_button',
                            label: 'agree',
                            error: 'account_already_exists'
                        })
                        break;
                    case errorMessages['LKS0506'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account_error_pop_up',
                            action: 'click_button',
                            label: 'agree',
                            error: 'account_suspended'
                        })
                        break;
                    default:
                        break;
                }
            } else {
                switch (popUpTitle) {
                    case errorMessages['LKS0503'].title:
                        pushEvent4('account_no_not_found');
                        break;
                    case errorMessages['LKS0504'].title:
                        pushEvent4('account_not_support');
                        break;
                    case errorMessages['LKS0505'].title:
                        pushEvent4('account_already_exists');
                        break;
                    case errorMessages['LKS0506'].title:
                        pushEvent4('account_suspended');
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const validateAccountID = (value: string) => {
        if (!value) return 'กรุณากรอกเลขที่บัญชี';
        if (value.replace(/\D/g, '').length !== 10) return 'กรุณากรอกเลขที่บัญชี 10 หลักให้ถูกต้อง';
        return null;
    };

    const checkIsCanGoNext = () => {
        const addAccountError = validateAccountID(accountID);
        if (addAccountError) {
            if (flow === 'confirm-account') {
                switch (addAccountError) {
                    case 'กรุณากรอกเลขที่บัญชี':
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account',
                            action: 'field_error',
                            label: 'account_no',
                            error: 'not_filled'
                        })
                        break;
                    case 'กรุณากรอกเลขที่บัญชี 10 หลักให้ถูกต้อง':
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_manage_account',
                            action: 'field_error',
                            label: 'account_no',
                            error: 'not_correct'
                        })
                        break;
                    default:
                        break;
                }
            } else {
                switch (addAccountError) {
                    case 'กรุณากรอกเลขที่บัญชี':
                        pushErrorFieldAppearReg('account_no', 'not_filled');
                        break;
                    case 'กรุณากรอกเลขที่บัญชี 10 หลักให้ถูกต้อง':
                        pushErrorFieldAppearReg('account_no', 'not_correct');
                        break;
                    default:
                        break;
                }
            }
        }
        setIsAccountIDValid(!addAccountError)
    };

    return (
        <PageProvider>
            <Title style={{ marginBottom: 8 }}>เพิ่มบัญชี</Title>
            <CustomInput
                title='เลขที่บัญชี'
                placeholder='ตัวอย่าง: 1234512341'
                value={accountID}
                onChange={handleAccountID}
                type='tel'
                validate={validateAccountID}
                format={formatAccountNumber}
                onBlur={checkIsCanGoNext}
                validateOnInit={false}
            />
            <ButtomSpan>
                {!isKeyboard && (
                    <div>
                        <Button style={{ marginBottom: 24 }} text={'ถัดไป'} type={!isAccountIDValid ? 1 : 0} onClick={goToNextPage} />
                        <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
                    </div>
                )}
            </ButtomSpan>
            {
                showPopup && <PopupAlert
                    title={popUpTitle}
                    iconType={iconType}
                    description={popUpDescription}
                    ButtonClick={onClosePopup} />
            }
        </PageProvider>
    );
}