export const getOSByPlatform = (): string => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      return 'IOS';
    } else if (userAgent.includes('android')) {
      return 'ANDROID';
    }
    return 'OTHERS';
};

export const getDeviceByPlatform = (): string => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const platform = window.navigator.platform.toLowerCase();

  if (platform.includes('iphone') || userAgent.includes('iphone')) {
    return 'iPhone';
  } else if (platform.includes('ipad') || userAgent.includes('ipad')) {
    return 'iPad';
  } else if (userAgent.includes('android') && userAgent.includes('mobile')) {
    return 'Android';
  } else if (userAgent.includes('android') && !userAgent.includes('mobile')) {
    return 'Tablet';
  } else if (platform.includes('mac') && 'ontouchend' in document) {
    return 'iPad';
  }

  return 'web';
};

export const isMobile = getOSByPlatform() !== 'web';
export const isModal = getDeviceByPlatform() === 'iPad' || getDeviceByPlatform() === 'Tablet';