import { networkReducer } from './networkReducer';
import { combineReducers } from 'redux';
import { registerReducer } from './registerReducer'
import { accountReducer } from './accountReducer';
import { settingReducer } from './settingReducer';

const rootReducer = combineReducers({
  register: registerReducer,
  account: accountReducer,
  setting: settingReducer,
  network: networkReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
