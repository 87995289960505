import { SET_ONLINE, SET_OFFLINE } from '../actions/networkAction';
import { RootState } from '../store/store';
import { createSelector } from '@reduxjs/toolkit';

export interface NetworkState {
    isOnline: boolean;
}

export interface NetworkAction {
    type: string;
}

const initialState: NetworkState = {
  isOnline: navigator.onLine,
};

export const networkReducer = (state: NetworkState = initialState, action: NetworkAction): NetworkState => {
  switch (action.type) {
    case SET_ONLINE:
      return { ...state, isOnline: true };
    case SET_OFFLINE:
      return { ...state, isOnline: false };
    default:
      return state;
  }
}

const selectNetwork = (state: RootState) => state.network

export const networkSelector = createSelector(
    [selectNetwork],
    (network) => {
        return {
            isOnline: network.isOnline,
        }
    }
)