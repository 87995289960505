import React from 'react';
import Colors from '../../assets/styles/Colors';
import { Fonts, FontSize } from '../../assets/styles/Fonts';

interface TextProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const Title: React.FC<TextProps> = ({ children, style }) => {
  return (
    <div
      style={{
        color: Colors.MAIN_TEXT_COLOR,
        fontFamily: Fonts.Krungsri_Bold,
        fontSize: FontSize.Title,
        lineHeight: '40px',
        // border: '1.5px solid #33abd0',
        ...style
      }}
    >
      {children}
    </div>
  );
};

export const SubTitle: React.FC<TextProps> = ({ children, style }) => {
  return (
    <div
      style={{
        color: Colors.MAIN_TEXT_COLOR,
        fontFamily: Fonts.Krungsri_Bold,
        fontSize: FontSize.Medium,
        lineHeight: '24px',
        // border: '1.5px solid #44abd0',
        ...style
      }}
    >
      {children}
    </div>
  );
};

export const Description: React.FC<TextProps> = ({ children, style }) => {
  return (
    <div
      style={{
        color: Colors.PRIMARY_TEXT_COLOR,
        fontFamily: Fonts.Thongterm_Medium,
        fontSize: FontSize.Medium,
        lineHeight: '29px',
        whiteSpace: 'pre-line',
        // border: '1.5px solid #000a04',
        ...style
      }}
    >
      {children}
    </div>
  );
};
