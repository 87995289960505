import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers/rootReducer';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { navigationRef } from '../utils/navigation';
import { setOnline, setOffline } from '../actions/networkAction';
import AccountDetailPage from '../features/account/pages/AccountDetailPage';
import AddAccountPage from '../features/account/pages/AddAccountPage';
import AddProductPage from '../features/account/pages/AddProductPage'
import ConfirmAccountPage from '../features/account/pages/ConfirmAccountPage';
import InfoPage from '../features/register/pages/InfoPage';
import WelcomePage from '../features/register/pages/WelcomePage';
import SurveyPage from '../features/success/pages/SurveyPage';
import TermsAndConditionsPage from '../features/register/pages/TermsAndConditionsPage';
import VerifyPage from '../features/register/pages/VerifyPage';
import SuccessPage from '../features/success/pages/SuccessPage';
import VerifyOTPPage from '../features/register/pages/VerifyOTPPage';
import ReceiveOTPPage from '../features/statement/pages/ReceiveOTPPage';
import RequestStatementPage from '../features/statement/pages/RequestStatementPage';
import PreStatementPage from '../features/statement/pages/preStatement';
import SuccessStatementPage from '../features/statement/pages/SuccessStatementPage';
import SurveySettingPage from '../features/setting/pages/SurveySettingPage';
import SuccessSettingPage from '../features/success/pages/SuccessSettingPage';
import LockedPage from '../features/register/pages/LockedPage';
import SuspendPage from '../features/register/pages/SuspendPage';
import SettingPage from '../features/setting/pages/SettingPage';
import HandleProductPage from '../features/account/pages/HandleProductPage';
import BindingConsentPage from '../features/register/pages/BindingConsentPage';
import BindingConsentForm from '../features/register/pages/BindingConsentForm';
import ManageConsentPage from '../features/register/pages/ManageConsentPage';
import ManageConsentForm from '../features/register/pages/ManageConsentForm';
import UnregisterPage from '../features/setting/pages/UnregisterPage';
import SuccessUnregisterPage from '../features/success/pages/SuccessUnregisterPage';
import SuccessConsent from '../features/register/pages/SuccessConsent';
import LandingPage from '../features/landing/pages/LandingPage';
import OtpPage from '../features/verification/pages/OtpPage';
import OpenKmaApp from '../features/redirect/pages/OpenKmaApp';
import SuccessSubmitAccountPage from '../features/success/pages/SuccessSubmitAccountPage';

export const routes = [
  { path: "/terms-and-conditions", component: TermsAndConditionsPage, exact: true },
  { path: "/verify", component: VerifyPage, exact: true },
  { path: "/info", component: InfoPage, exact: true },
  { path: "/locked-register", component: LockedPage, exact: true },
  { path: "/suspend-register", component: SuspendPage, exact: true },
  { path: "/add-account", component: AddAccountPage, exact: true },
  { path: "/add-account/:account_no", component: AccountDetailPage, exact: true },
  { path: "/add-product", component: AddProductPage, exact: true },
  { path: "/confirm-account", component: ConfirmAccountPage, exact: true },
  { path: "/survey", component: SurveyPage, exact: true },
  { path: "/success", component: SuccessPage, exact: true },
  { path: "/success-account", component: SuccessSubmitAccountPage, exact: true },
  { path: "/verify-otp-page", component: VerifyOTPPage, exact: true },
  { path: "/receive-otp", component: ReceiveOTPPage, exact: true },
  { path: "/select-statement", component: RequestStatementPage, exact: true },
  { path: "/request-statement", component: PreStatementPage, exact: true },
  { path: "/success-statement", component: SuccessStatementPage, exact: true },
  { path: "/setting-survey", component: SurveySettingPage, exact: true },
  { path: "/setting-success", component: SuccessSettingPage, exact: true },
  { path: "/setting", component: SettingPage, exact: true },
  { path: "/handle-product", component: HandleProductPage, exact: true },
  { path: "/unbinding", component: UnregisterPage, exact: true},
  { path: "/success-unbinding", component: SuccessUnregisterPage, exact: true },
  { path: "/binding-consent", component: BindingConsentPage, exact: true },
  { path: "/binding-consent-form", component: BindingConsentForm, exact: true },
  { path: "/manage-consent", component: ManageConsentPage, exact: true},
  { path: "/manage-consent-form", component: ManageConsentForm, exact: true},
  { path: "/success-consent/:withdraw", component: SuccessConsent, exact: true },
  { path: "/register", component: WelcomePage, exact: true },
  { path: "/otp", component: OtpPage, exact: true },
  { path: "/redirect-kma", component: OpenKmaApp, exact: true },
  { path: "*", component: LandingPage, exact: true }
];

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [checkingFlow, setCheckingFlow] = useState(true);

  useEffect(() => {
    const updateNetworkStatus = () => {
      if (navigator.onLine) {
        dispatch(setOnline());
      } else {
        dispatch(setOffline());
      }
    };

    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);
    updateNetworkStatus();
    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, [dispatch]);


  useEffect(() => {
    navigationRef.current = (path: string) => navigate(path, { replace: true });
}, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accountRef = params.get('accountRef');

    if (accountRef) {
      localStorage.setItem('accountRef', accountRef);
    }


    setCheckingFlow(false);
  }, [location.search, navigate]);

  const renderRoutes = (routes: any[] = []) => (
    <Routes>
      {routes.map((route, i) => {
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={route.routes ? renderRoutes(route.routes) : <Component />}
          />
        );
      })}
    </Routes>
  );

  if (checkingFlow) {
    return null;
  }

  return renderRoutes(routes);
};

export default AppRoutes;