import React, { useEffect } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ScreenAlert from '../../../components/common/ScreenAlert';
import Button from '../../../components/common/Button';
import BottomSpan from '../../../components/layout/BottomSpan';
import { exchangeLineToken } from '../../../actions/tokenAction';
import { useLineAuth } from '../../../hooks/useLineAuth';
import { useDispatch } from 'react-redux';
import { unregisterUser } from '../../../actions/settingAction';
import { getOSByPlatform } from '../../../utils/getOSByPlatform'
import { safeNavigate } from '../../../utils/navigation';
import { useLocation } from 'react-router-dom';
import { logEvent } from '../../../utils/dataLayers/analytics';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';

export default function UnregisterPage() {
    const { lineIdToken } = useLineAuth()
    const dispatch = useDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const iconSourcesParams = params.get("iconSources")
    const titleParams = params.get("title")
    const typeParams = params.get("type")
    const descParams = params.get("desc")
    const iconSources = iconSourcesParams? iconSourcesParams : '';
    const title = titleParams? titleParams : '';
    const type = typeParams? Number(typeParams) : 1;
    const desc = descParams? descParams : '';


    useEffect(() => {
        const fetchAndExchangeToken = async () => {
          const decodedUri = decodeURIComponent(location.search);
          const params = new URLSearchParams(decodedUri);
          console.log('param',params)
          const authenticationReference = params.get('authenticationReference');
      
          console.log('authenticationReference', authenticationReference);

          if (authenticationReference) {
            sessionStorage.setItem('authRef', authenticationReference);
      
            if (lineIdToken) {
              console.log('lineIdToken', lineIdToken);
              dispatch(exchangeLineToken(lineIdToken, '/unbinding'));
            }
          }
        };
      
        fetchAndExchangeToken();
      }, [lineIdToken, location.search]);

    const goToNextPage = () => {
        dispatch(unregisterUser(getOSByPlatform()));
        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_unbinding',
            action: 'click_button',
            label: 'confirm_unbinding',
        })
    };

    const goToPreviousPage = () => {
        safeNavigate('/setting');
        logEvent({
          event: 'track_event',
          category: 'line_krungsri_simple_unbinding',
          action: 'click_button',
          label: 'cancel_unbinding',
      })
    };
   
    return (
        <PageProvider>
            <div style={{
                margin: '0 auto',
                textAlign: 'center',
                paddingTop: '15vh',
            }}>
                <img src={'/svg/alert.svg'} alt="Popup Icon" style={{
                    width: '88px',
                    height: '88px',
                    marginBottom: '4vh',
                }} />
                <div style={{ color: Colors.MAIN_TEXT_COLOR, fontFamily: Fonts.Krungsri_Bold, fontSize: '24px', lineHeight: '36px', marginBottom: '8px',whiteSpace: 'pre-line', }}>
                    {'ยกเลิกการสมัครใช้บริการ\nKrungsri Simple หรือไม่'}
                </div>
                <div style={{ color: Colors.PRIMARY_TEXT_COLOR, fontFamily: Fonts.Thongterm_Medium, fontSize: '16px', lineHeight: '29px', whiteSpace: 'pre-line', }}>
                    {`การยกเลิกจะทำให้คุณไม่สามารถรับแจ้งเตือน\nเงินเข้า-ออกบัญชี, เช็กยอด, ขอ Statement\nและบริการอื่น ๆ ผ่าน Krungsri Simple ได้`}
                </div>
            </div>
            
            <BottomSpan>
                <Button text={'ยกเลิกบริการ'} onClick={() => goToNextPage()} type={0} />
                <div style={{ marginBottom: 24 }} />
                <Button text={'ใช้บริการต่อ'} onClick={() => goToPreviousPage()} type={2} />
            </BottomSpan>
        </PageProvider>
    )
}
